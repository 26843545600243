import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import { connect } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import { FaHeartbeat } from 'react-icons/fa';
import LockIcon from '@mui/icons-material/Lock';

import { hapticsImpactMedium } from '../services/iosHaptics';

import './Button.scss';

const mapStateToProps = (state) => ({
  userIsPremium:
    state.user.role === 'premium' || state.user.role === 'superuser'
      ? true
      : false,
});

const Button = ({
  picture,
  label,
  onClick,
  color,
  type,
  reverse,
  badge,
  big,
  component,
  forPremium,
  userIsPremium,
  forPremiumNoPay,
  small,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    hapticsImpactMedium();
    if (forPremiumNoPay && !userIsPremium) {
      navigate('/pricing');
    } else if (onClick) {
      onClick();
    }
  };

  const setClassNames = () => {
    let classNames = 'Button';
    switch (color) {
      case 1:
        classNames += ' color-1 glow-1-l';
        break;
      case 2:
        classNames += ' color-2 glow-2-l';
        break;
      case 3:
        classNames += ' color-3 glow-3-l';
        break;
      case '3-l':
        classNames += ' color-3-l glow-1-l';
        break;

      case 'white':
        classNames += ' Button--white Button--rounded ';
        break;
      case 'white--1':
        classNames += ' Button--white--1 Button--rounded  ';
        break;
      case 'dark':
        classNames += ' Button--dark Button--rounded ';
        break;

      default:
        break;
    }
    if (reverse) {
      classNames += ' Button--reverse';
    }
    if (big) {
      classNames += ' Button--big';
    }
    if (small) {
      classNames += ' Button--small';
    }
    if (!label) {
      classNames += ' Button--rounded';
    }
    return classNames;
  };

  return (
    <button type={type} onClick={handleClick}>
      <div className={setClassNames()}>
        {picture && (
          <SvgIcon
            component={forPremiumNoPay && !userIsPremium ? LockIcon : picture}
            alt=""
          />
        )}
        {component && component}

        {label && <div className="Button__label">{label}</div>}
        {badge ? <div className="Button__badge">{badge}</div> : null}
      </div>
      {forPremium && !userIsPremium && (
        <div className=" color-3 flex-h-c bold mt5">
          <FaHeartbeat className="mr5" />- 100
        </div>
      )}
      {forPremiumNoPay && !userIsPremium && (
        <div className="small color-3 flex-h-c bold mt5">
          <FaHeartbeat className="mr5" />
          Backstage uniquement
        </div>
      )}
    </button>
  );
};

export default connect(mapStateToProps)(Button);

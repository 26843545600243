import './NotificationsContainer.scss';

import React from 'react';
import { connect } from 'react-redux';

import { showNotifications } from '../../store/actions';
import { getNotifs } from '../../store/selectors';

import Notification from './Notification';
import { Fade } from 'react-reveal';

const mapStateToProps = (state) => ({
  notifications: state.notifications,
  notificationsItems: getNotifs(state),
});
const mapDispatchToProps = (dispatch) => ({
  showNotifications: (bool) => dispatch(showNotifications(bool)),
});

const NotificationsContainer = ({
  notifications,
  showNotifications,
  notificationsItems,
}) => {
  return (
    <Fade right when={notifications.show} duration={500}>
      <div
        style={!notifications.show && { display: 'none' }}
        className="NotificationsContainer"
        onClick={() => showNotifications(false)}
      >
        {notifications &&
          notifications.show &&
          notificationsItems.map((notification, index) => (
            <Notification key={index} notification={notification} />
          ))}
      </div>
      <div
        className="NotificationsContainer__overlay"
        onClick={() => showNotifications(false)}
        style={!notifications.show && { display: 'none' }}
      ></div>
    </Fade>
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsContainer);

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthValidator from "./components/AuthValidator";
import Landing from "./pages/Landing";

import store from "./store";
// import Generator from './pages/Generator';
import AppUrlListener from "./components/AppUrlListener";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import Toolbar from "./components/Toolbar/Toolbar";
import Admin from "./pages/Admin/Admin";
import Stats from "./pages/Admin/Stats";
import User from "./pages/Admin/User";
import Eula from "./pages/Legal/EULA";
import Legal from "./pages/Legal/Legal";
import Press from "./pages/Legal/Press";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";
import TermsOfUse from "./pages/Legal/TermsOfUse";
import P404 from "./pages/P404";
import SinginMailConfirm from "./pages/Signin/SinginMailConfirm";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <AppUrlListener />
            <Routes>
              <Route path="/" element={<Landing />} />
              {/* <Route path="/spotifyconnect" element={<SpotifyConnect />} />
              <Route path="/deezerconnect" element={<DeezerConnect />} />
              <Route
                path="/login"
                element={
                  <>
                    <Login />
                    <Toolbar backButton userIsNotLoggedIn />
                  </>
                }
              /> */}
              {/* <Route path="/generator" element={<Generator />} /> */}
              <Route
                path="/about/privacy"
                element={
                  <>
                    <PrivacyPolicy />
                    <Toolbar backButton userIsNotLoggedIn />
                  </>
                }
              />
              <Route
                path="/about/press"
                element={
                  <>
                    <Press />
                    <Toolbar backButton userIsNotLoggedIn />
                  </>
                }
              />
              <Route
                path="/about/termsofuse"
                element={
                  <>
                    <TermsOfUse />
                    <Toolbar backButton userIsNotLoggedIn />
                  </>
                }
              />
              <Route
                path="/about/legal"
                element={
                  <>
                    <Legal />
                    <Toolbar backButton userIsNotLoggedIn />
                  </>
                }
              />
              <Route path="/about/eula" element={<Eula />} />
              <Route path="/mailconfirm" element={<SinginMailConfirm />} />

              {/* <Route
                path="/home"
                element={
                  <AuthValidator>
                    <Header />
                    <Home />
                    <Toolbar backButton={false} />
                  </AuthValidator>
                }
              /> */}
              {/* <Route
                path="/faq"
                element={
                  <AuthValidator>
                    <Header />
                    <Faq />
                    <Toolbar backButton={true} />
                  </AuthValidator>
                }
              /> */}
              {/* <Route
                path="/pricing"
                element={
                  <AuthValidator>
                    <Header />
                    <Pricing />
                    <Toolbar backButton={true} />
                  </AuthValidator>
                }
              /> */}
              <Route
                path="/admin"
                element={
                  <AuthValidator>
                    <Header />
                    <Admin />
                  </AuthValidator>
                }
              />
              <Route
                path="/admin/user/:id"
                element={
                  <AuthValidator>
                    <Header />
                    <User />
                  </AuthValidator>
                }
              />
              <Route
                path="/admin/stats"
                element={
                  <AuthValidator>
                    <Header />
                    <Stats />
                  </AuthValidator>
                }
              />

              {/* <Route
                path="/search"
                element={
                  <AuthValidator>
                    <Header />
                    <Search />
                    <Toolbar backButton={true} />
                  </AuthValidator>
                }
              /> */}
              {/* <Route
                path="/search/favorites"
                element={
                  <AuthValidator>
                    <Header />
                    <SearchFavs />
                    <Toolbar backButton={false} />
                  </AuthValidator>
                }
              /> */}
              {/* <Route
                path="/search/favoritesyou"
                element={
                  <AuthValidator>
                    <Header />
                    <SearchFavsYou />
                    <Toolbar backButton={false} />
                  </AuthValidator>
                }
              /> */}
              {/* <Route
                path="/search/track/:term"
                element={
                  <AuthValidator>
                    <Header />
                    <SearchByTrack />
                    <Toolbar backButton={true} />
                  </AuthValidator>
                }
              /> */}
              {/* <Route
                path="/search/track/id/:term"
                element={
                  <AuthValidator>
                    <Header />
                    <SearchByTrackId />
                    <Toolbar backButton={true} />
                  </AuthValidator>
                }
              /> */}
              {/* <Route
                path="/search/username/:term"
                element={
                  <AuthValidator>
                    <Header />
                    <SearchByUsername />
                    <Toolbar backButton={true} />
                  </AuthValidator>
                }
              /> */}

              {/* <Route
                path="/account"
                element={
                  <AuthValidator>
                    <Header />
                    <Account />
                    <Toolbar backButton={false} />
                  </AuthValidator>
                }
              /> */}

              {/* <Route
                path="/account/edit"
                element={
                  <AuthValidator>
                    <Header />
                    <AccountSearchProfileEdit />
                    <Toolbar backButton={true} />
                  </AuthValidator>
                }
              /> */}
              {/* <Route
                path="/signin"
                element={
                  <AuthValidator noGetUserData>
                    <Signin />
                  </AuthValidator>
                }
              /> */}
              {/* <Route
                path="/musicsync"
                element={
                  <AuthValidator>
                    <AccountMusicSync />
                  </AuthValidator>
                }
              /> */}
              {/* <Route
                path="/description"
                element={
                  <AuthValidator>
                    <MusicDescPage />
                  </AuthValidator>
                }
              /> */}
              {/* <Route
                path="/description/edit/"
                element={
                  <AuthValidator>
                    <Header />
                    <MusicDescEdit />
                  </AuthValidator>
                }
              /> */}
              {/* <Route
                path="/match/:matchId"
                element={
                  <AuthValidator>
                    <Header />
                    <Match />
                    <Toolbar backButton={true} />
                  </AuthValidator>
                }
              /> */}
              {/* <Route
                path="/chat"
                element={
                  <AuthValidator>
                    <Header />
                    <Conversations />
                    <Toolbar backButton={false} />
                  </AuthValidator>
                }
              /> */}
              {/* <Route
                path="/chat/conversations/:id"
                element={
                  <AuthValidator>
                    <Header />
                    <Conversation />
                  </AuthValidator>
                }
              /> */}
              {/* <Route
                path="/contact"
                element={
                  <AuthValidator>
                    <Header />
                    <Contact />
                    <Toolbar backButton={false} />
                  </AuthValidator>
                }
              /> */}
              {/* <Route path="/test" element={<SinginActivateWithoutCode />} />
              <Route path="/test2" element={<MusicDescPage />} /> */}
              {/* <Route
                path="/resetpassword/:token"
                element={
                  <>
                    <PasswordReset />
                    <Toolbar backButton userIsNotLoggedIn />
                  </>
                }
              /> */}
              {/* <Route path="/endsignin" element={<EndSigninMobile />} /> */}
              <Route path="*" element={<P404 />} />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;

import './P404.scss';

import logo from '../assets/logodegrade2.png';

import React from 'react';

import { useNavigate } from 'react-router-dom';

const P404 = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  return (
    <div className="P404" onClick={handleClick}>
      <img src={logo} alt="" />
      <h1>#404</h1>
      <q>Zut ! Cette page n'existe pas, cliquez n'importe où</q>
    </div>
  );
};

export default P404;

import axios from 'axios';
// import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { apiUrl } from './env_vars';
import { alertMessage, logout, setErrors } from '../store/actions';

export const setInterceptors = () => {
  axios.interceptors.request.use(
    (config) => {
      console.log('config', config);
      const token = localStorage.getItem('token');
      const customHeaders = {};
      // if (config._jsonld) {
      //   customHeaders['Accept'] = 'application/ld+json';
      // } else {
      //   customHeaders['Accept'] = 'application/json';
      // }
      // if (config.method === 'patch') {
      //   customHeaders['content-type'] = 'application/merge-patch+json';
      // }
      if (config._formData) {
        customHeaders['content-type'] = 'multipart/form-data';
      }

      if (token) {
        //TODO: provisoire-----
        if (!config._noAuth) {
          customHeaders.Authorization = `Bearer ${token}`;
        }
        //---------------------
      }
      config.headers = customHeaders;
      console.log(config);
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // const refreshAuthLogic = async () => {
  //   try {
  //     await api.refreshToken();
  //   } catch (error) {
  //     store.dispatch(setErrors(['Vous êtes déconnecté']));
  //     store.dispatch(logout());
  //   }
  //   return Promise.resolve();
  // };

  // createAuthRefreshInterceptor(axios, refreshAuthLogic, {
  //   pauseInstanceWhileRefreshing: true,
  //   statusCodes: [401, 403],
  // });

  axios.interceptors.response.use(undefined, async (error) => {
    if (error.response.data.action === 'logout') {
      // store.dispatch(setErrors([error.response.data.message]));
      // console.log('axios interceptor error', error.response);
      // store.dispatch(logout());
    }

    if (error.response.data.action === 'alert') {
      // store.dispatch(
      //   alertMessage({
      //     message: error.response.data.message,
      //   })
      // );
      console.log('axios interceptor error', error.response);
    }

    if (error.response.status === 500) {
      // store.dispatch(
      //   alertMessage({
      //     message: 'Erreur technique, réessayer plus tard',
      //   })
      // );
    }

    if (error.response.status === 504) {
      // store.dispatch(
      //   alertMessage({
      //     message: 'Erreur technique, veuillez réessayer plus tard',
      //   })
      // );
    }

    if (error.response.status === 503) {
      // store.dispatch(
      //   setErrors([
      //     'Erreur technique avec votre service de streaming musical, veuillez réessayer plus tard',
      //   ])
      // );
      // store.dispatch(
      //   alertMessage({
      //     message:
      //       'Erreur technique avec votre service de streaming musical, veuillez réessayer plus tard',
      //   })
      // );
      // store.dispatch(logout());
    }

    if (typeof error.response === 'undefined') {
      // store.dispatch(
      //   setErrors([
      //     'Erreur serveur, nous faisons au plus vite pour régler ce problème',
      //   ])
      // );

      console.log('axios interceptor error', error.response);

      // store.dispatch(logout());
    }

    console.log('axios error interceptor OK');
    return Promise.reject(error);
  });
};

const api = {
  login: async (user) => {
    const response = await axios.post(`${apiUrl}/login`, user);
    const token = response.data.token;
    localStorage.setItem('token', token);
    localStorage.setItem('isAuth', true);

    return response;
  },

  pushConnect: async (user) => {
    const response = await axios.get(`${apiUrl}/me/pushConnect`);
    return response;
  },
  pushVisit: async (user) => {
    const response = await axios.get(`${apiUrl}/me/pushVisit`);
    return response;
  },

  sendResetPasswordMail: async (email) => {
    const response = await axios.post(`${apiUrl}/me/passwordreset`, {
      email,
    });
    return response;
  },

  deleteMe: async () => {
    const response = await axios.delete(`${apiUrl}/me`);

    return response;
  },

  // checkUserLogin: async () => {
  //   // OBSOLETE
  //   const response = await axios.get(`${apiUrl}/me`);
  //   return response;
  // },

  registerUser: async (user) => {
    //inscription d'un utilisateur et récupération d'un token
    const response = await axios.post(`${apiUrl}/register`, user);
    console.log('api registerUser response', response);

    const token = response.data.token;

    localStorage.setItem('token', token);
    localStorage.setItem('isAuth', true);
    return response;
  },

  updateUserData: async (userData) => {
    const response = await axios.post(`${apiUrl}/me/data`, userData);
    console.log('api createUserData response', response);
    return response;
  },

  updateUserDataSearchProfile: async (userData) => {
    const response = await axios.post(`${apiUrl}/me/data`, userData);
    console.log('api createUserData response', response);
    return response;
  },

  updateUser: async (user) => {
    const response = await axios.post(`${apiUrl}/me`, user);
    console.log('api updateUser response', response);
    return response;
  },

  getActivationCode: async () => {
    const response = await axios.get(`${apiUrl}/me/activate`);
    return response;
  },
  activateWithoutCode: async () => {
    const response = await axios.get(`${apiUrl}/me/activatewithoutcode`);
    return response;
  },

  checkEmailVerificationCode: async (code) => {
    const response = await axios.post(`${apiUrl}/me/activate`, { code: code });
    return response;
  },

  resendEmailVerificationCode: async () => {
    const response = await axios.get(`${apiUrl}/me/activate`);
    return response;
  },

  getUserData: async () => {
    const response = await axios.get(`${apiUrl}/me`);
    console.log('api getUserData response', response);
    return response;
  },

  getUserById: async (id) => {
    const response = await axios.get(`${apiUrl}/users/${id}`);
    return response;
  },

  // CHAT --------------------------------------------------

  getOrCreateConversation: async ({ matchId }) => {
    const response = await axios.get(
      `${apiUrl}/match/${matchId}/conversations`
    );
    return response;
  },

  getUserConversations: async ({ page, limit }) => {
    const response = await axios.get(
      `${apiUrl}/me/conversations?page=${page}&limit=${limit}`
    );
    console.log('api getUserConversations response', response);
    return response;
  },

  getConversation: async (id) => {
    const response = await axios.get(`${apiUrl}/conversations/${id}`);
    console.log('api getConversation response', response);
    return response;
  },

  getConversationMessages: async ({ id, limit }) => {
    const response = await axios.get(
      `${apiUrl}/conversations/${id}/messages?limit=${limit}`
    );
    return response;
  },

  checkConversations: async () => {
    const response = await axios.get(`${apiUrl}/me/conversations/check`);
    return response;
  },

  postMessage: async ({ id, message }) => {
    const response = await axios.post(
      `${apiUrl}/conversations/${id}/messages`,
      {
        message,
      }
    );
    console.log('api postMessage response', response);
    return response;
  },

  block: async ({ id }) => {
    const response = await axios.get(`${apiUrl}/conversations/${id}/block`);
    return response;
  },

  unBlock: async ({ id }) => {
    const response = await axios.get(`${apiUrl}/conversations/${id}/unblock`);
    return response;
  },

  // ------------------------------------------------------

  getOneTrack: async (id) => {
    const response = await axios.get(`${apiUrl}/tracks/${id}`);
    console.log('api getOneTrack response', response);
    return response;
  },

  getTracksFromUserId: async (id) => {
    const response = await axios.get(`${apiUrl}/users/${id}/tracks`);
    return response;
  },

  resetUserTracks: async () => {
    const response = await axios.get(`${apiUrl}/me/tracks/sync/init`);
    console.log('api initSync response', response);
    return response;
  },

  syncTracks: async (tracks, config) => {
    // config { last: bool, newUser: bool }

    if (config.newUser) {
      const response = await axios.post(`${apiUrl}/me/tracks`, { tracks });
      return response;
    } else {
      if (config.last) {
        const response = await axios.post(`${apiUrl}/me/tracks/sync`, {
          tracks: tracks,
          last: true,
        });
        return response;
      } else {
        const response = await axios.post(`${apiUrl}/me/tracks/sync`, {
          tracks: tracks,
          last: false,
        });
        return response;
      }
    }
  },

  getUserTracks: async ({ page, limit }) => {
    const response = await axios.get(
      `${apiUrl}/me/tracks?limit=${limit}&page=${page}`
    );
    return response;
  },

  postUserPicture: async (picture) => {
    const formData = new FormData();
    formData.append('file', picture, 'picture.jpg');
    const response = await axios.post(`${apiUrl}/upload`, formData, {
      _formData: true,
    });
    console.log('postUserPicture', response);

    return response;
  },

  searchTracks: async (query) => {
    const response = await axios.get(
      `${apiUrl}/me/tracks/search?query=${query}`
    );
    return response;
  },

  getMusicDescCategories: async () => {
    const response = await axios.get(`${apiUrl}/musicdesccategories`);
    const musicCategories = response.data;
    await Promise.all(
      musicCategories.map(async (musicCategory) => {
        const response = await axios.get(
          `${apiUrl}/me/musicdesc/category/${musicCategory.id}`
        );
        return (musicCategory.track = response.data.track);
      })
    );
    return musicCategories;
  },

  getMusicDescs: async () => {
    const response = await axios.get(`${apiUrl}/me/musicdesc/`);
    return response;
  },

  setTrackInMusicDescCategory: async ({ trackId, musicDescCategoryId }) => {
    const response = await axios.post(`${apiUrl}/me/musicdesc`, {
      trackId,
      musicDescCategoryId,
    });
    return response;
  },

  // MATCH --------------------------------------------------

  reportMatch: async ({ reason, message, reportUserId }) => {
    const response = await axios.post(`${apiUrl}/me/matchs/signal`, {
      reason,
      message,
      reportUserId,
    });
    return response;
  },
  calcMatchsForAllUsers: async () => {
    const response = await axios.get(`${apiUrl}/matchs/updateall`);
    return response;
  },

  syncMatchsWithScope: async () => {
    const response = await axios.get(`${apiUrl}/me/matchs/sync`);
    return response;
  },

  getMatchTracks: async ({ matchId, limit, page }) => {
    const response = await axios.get(
      `${apiUrl}/matchs/${matchId}/tracks?limit=${limit}&page=${page}`
    );
    return response;
  },

  getMatchArtists: async ({ matchId, limit, page }) => {
    const response = await axios.get(
      `${apiUrl}/matchs/${matchId}/artists?limit=${limit}&page=${page}`
    );
    return response;
  },

  getMatch: async (matchId) => {
    const response = await axios.get(`${apiUrl}/matchs/${matchId}`);
    console.log('api getMatch response', response);
    return response;
  },

  getAllMatchsFromUser: async (config) => {
    // config obligatoire : { page: number, limit: number, orderType: string, order: string }
    // config optionnelle : { searchBy: { type: 'track', term: 'string' } }
    // config optionnelle : { searchBy: { type: 'trackId', term: 'id' } }
    config.page = config.page ? config.page : 1;
    config.limit = config.limit ? config.limit : 10;

    let response;

    console.log('CONFIG', config);

    if (config.searchBy && config.searchBy.type === 'track') {
      response = await axios.get(
        `${apiUrl}/me/matchs/searchByTrack?page=${config.page}&limit=${config.limit}&term=${config.searchBy.term}`
      );
    } else if (config.searchBy && config.searchBy.type === 'username') {
      response = await axios.get(
        `${apiUrl}/me/matchs/searchByUsername?page=${config.page}&limit=${config.limit}&term=${config.searchBy.term}`
      );
    } else if (config.searchBy && config.searchBy.type === 'trackId') {
      response = await axios.get(
        `${apiUrl}/me/matchs/searchByTrackId?page=${config.page}&limit=${config.limit}&trackId=${config.searchBy.term}`
      );
    } else {
      response = await axios.get(
        `${apiUrl}/me/matchs?page=${config.page}&limit=${config.limit}&orderBy=${config.orderBy}&order=${config.order}`
      );
    }
    const matchsFromDb = response.data.items;

    const total = response.data.total;
    const matchs = {
      items: matchsFromDb,
      total: total,
    };

    return matchs;
  },

  getMatchsFromUserId: async (id) => {
    const response = await axios.get(`${apiUrl}/users/${id}/matchs`);
    return response;
  },

  //FAVS

  getFavMatchsFromUser: async (config) => {
    // config obligatoire : { page: number, limit: number }
    config.page = config.page ? config.page : 1;
    config.limit = config.limit ? config.limit : 10;

    const response = await axios.get(
      `${apiUrl}/me/favs?page=${config.page}&limit=${config.limit}`
    );
    const favs = response.data.items;

    const total = response.data.total;
    const matchs = {
      items: favs,
      total: total,
    };

    return matchs;
  },

  getFavsWhoFavUser: async (config) => {
    // config obligatoire : { page: number, limit: number, orderType: string, order: string }
    // config optionnelle : { isFavorite: true, isFavoriteByUm: true }
    config.page = config.page ? config.page : 1;
    config.limit = config.limit ? config.limit : 10;

    const response = await axios.get(
      `${apiUrl}/me/favsme?page=${config.page}&limit=${config.limit}`
    );
    const favs = response.data.items;

    const total = response.data.total;
    const matchs = {
      items: favs,
      total: total,
    };

    return matchs;
  },

  checkIsFav: async (matchId) => {
    const response = await axios.get(
      `${apiUrl}/me/favs/checkmatch?id=${matchId}`
    );
    console.log('api checkIsFav response', response);
    return response.data;
  },
  addFav: async (matchId) => {
    const response = await axios.post(`${apiUrl}/me/favs?id=${matchId}`);
    console.log('api addFav response', response);
    return response;
  },
  removeFav: async (matchId) => {
    const response = await axios.delete(`${apiUrl}/me/favs?id=${matchId}`);
    console.log('api removeFav response', response);
    return response;
  },

  //CONTACT
  sendMailToMatchistador: async ({ message }) => {
    const response = await axios.post(`${apiUrl}/contact`, { message });
    console.log('api sendMailToMatchistador response', response);
    return response;
  },

  //ADMIN
  getAllUsers: async (config) => {
    // config obligatoire : { page: number, limit: number }
    config.page = config.page ? config.page : 1;
    config.limit = config.limit ? config.limit : 30;

    const response = await axios.get(
      `${apiUrl}/users?page=${config.page}&limit=${config.limit}`
    );
    const users = response.data.items;

    const total = response.data.total;
    const usersFromDb = {
      items: users,
      total: total,
    };

    return usersFromDb;
  },
  searchUsers: async (config) => {
    // config obligatoire : { page: number, limit: number }
    config.page = config.page ? config.page : 1;
    config.limit = config.limit ? config.limit : 30;

    const response = await axios.get(
      `${apiUrl}/users/search?term=${config.term}&page=${config.page}&limit=${config.limit}`
    );
    const users = response.data.items;

    const total = response.data.total;
    const usersFromDb = {
      items: users,
      total: total,
    };

    return usersFromDb;
  },

  getTokenByUserId: async (id) => {
    const response = await axios.get(`${apiUrl}/admin/login/${id}`);
    return response;
  },

  calcMatchsForUser: async (userId) => {
    const response = await axios.get(`${apiUrl}/users/${userId}/matchs/sync/`);
    return response;
  },

  clearPicture: async (userId) => {
    const response = await axios.get(`${apiUrl}/users/${userId}/clearpicture`);
    return response;
  },

  //ONESECOND
  oneSecondGetCurrent: async () => {
    const response = await axios.get(
      `${apiUrl}/onesecond/current?origin=banjo`
    );
    return response;
  },
  oneSecondGetLasts: async (config) => {
    const response = await axios.get(`${apiUrl}/onesecond/lasts`);
    return response;
  },

  //STATS
  getStatsTopTracks: async () => {
    const response = await axios.get(`${apiUrl}/stats/tracks`);
    return response;
  },
  getStatsTopArtists: async () => {
    const response = await axios.get(`${apiUrl}/stats/artists`);
    return response;
  },
};
export default api;

import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import TrackRow from '../MusicDesc/TrackRow';
import './Stats.scss';

const Stats = () => {
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const responseTracks = await api.getStatsTopTracks();
      const responseArtists = await api.getStatsTopArtists();
      setTopTracks(responseTracks.data);
      setTopArtists(responseArtists.data);
      setLoading(false);
    };
    init();
  }, []);

  const [loading, setLoading] = useState(false);
  const [topTracks, setTopTracks] = useState([]);
  const [topArtists, setTopArtists] = useState([]);

  return (
    <div className="main-container">
      <div className="Stats">
        <div className="Stats__container">
          <h2 className="mb10">Top Tracks</h2>
          {topTracks.map((track, index) => (
            <div className="Stats__item">
              <div className="Stats__item__number">{index + 1}</div>
              <TrackRow key={track.id} track={track} />
            </div>
          ))}
        </div>
        <div className="Stats__container">
          <h2 className="mb10">Top Artists</h2>

          {topArtists.map((artist, index) => (
            <div className="Stats__item">
              <div className="Stats__item__number">{index + 1}</div>
              <div className="Stats__artist">
                <span className="color-2">{artist.artist_name}</span> -{' '}
                <span className="small">{artist.usercount}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stats;

import React from 'react';

import { Pagination } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5fd3e0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fd4b5d',
    },
    text: {
      primary: {
        color: '#fff',
      },
    },
  },
});

const PaginationToolbar = ({ count, page, onChange }) => {
  return (
    <div className="w100 flex-v-c mt20 mb20">
      <ThemeProvider theme={theme}>
        <Pagination
          color="primary"
          count={count}
          page={page}
          onChange={onChange}
        />
      </ThemeProvider>
    </div>
  );
};

export default PaginationToolbar;

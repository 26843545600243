import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getUserData } from '../store/actions';

const mapStateToProps = (state) => ({
  hasLogout: state.user.hasLogout,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  getUserData: () => dispatch(getUserData()),
});

const AuthValidator = ({
  hasLogout,
  children,
  user,
  getUserData,
  noGetUserData,
}) => {
  useEffect(() => {
    if (!noGetUserData) {
      if (!user.userData.id || !user.id) {
        getUserData();
      }
    }
  }, []);
  return hasLogout === true ? <Navigate to="/login" /> : children;
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthValidator);

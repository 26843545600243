import './Legal.scss';

import React from 'react';
import { useNavigate } from 'react-router';
import logo from '../../assets/logodegrade2.png';

import Button from '../../components/Button';

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  return (
    <div className="main-container">
      <div className="Legal">
        <img src={logo} alt="Logo Banjo" className="Legal__logo" />
        <h2>Politique de confidentialité</h2>
        <h3>Introduction</h3>
        <p>
          Dans le cadre de son activité, Banjo est amené à collecter et à
          traiter des informations dont certaines sont qualifiées de « données
          personnelles ». Banjo attache une grande importance au respect de la
          vie privée, et n’utilise que des donnes de manière responsable et
          confidentielle et dans une finalité précise.
        </p>
        <h3>Données personnelles</h3>
        <p>Banjo est susceptible de recueillir 2 types de données :</p>
        <ul>
          <li>
            Les données transmises directement : Ces données sont celles que
            vous nous transmettez directement, via un formulaire d'inscription
            ou bien par contact direct par mail.
          </li>
          <li>
            Les données collectées automatiquement : Lors de vos visites, une
            fois votre consentement donné, nous pouvons recueillir des
            informations de type « web analytics » relatives à votre navigation,
            la durée de votre consultation, votre adresse IP, votre type et
            version de navigateur. La technologie utilisée est le cookie.
          </li>
        </ul>
        <h3>Utilisation des données personnelles</h3>
        <p>
          Les données que vous nous transmettez directement sont utilisées dans
          le but de générer des matchs pertinents. Les données « web analytics »
          sont collectées de forme anonyme (en enregistrant des adresses IP
          anonymes) par Google Analytics, et nous permettent de mesurer
          l’audience de notre site web, les consultations et les éventuelles
          erreurs afin d’améliorer constamment l’expérience des utilisateurs.
          Ces données sont utilisées par Banjo, responsable du traitement des
          données, et ne seront jamais cédées à un tiers ni utilisées à d’autres
          fins que celles détaillées ci-dessus.
        </p>
        <h3>Base légale</h3>
        <p>
          Les données personnelles ne sont collectées qu’après consentement
          obligatoire de l’utilisateur. Ce consentement est valablement
          recueilli (boutons et cases à cocher), libre, clair et sans équivoque.
        </p>
        <h3>Durée de consevation</h3>
        <p>
          Les données seront sauvegardées durant une durée maximale de 3 ans.
        </p>
        <h3>Cookies</h3>
        Voici la liste des cookies utilisées et leur objectif :
        <ul>
          <li>Cookies Google Analytics : Web analytics</li>
          <li>
            Cookies de session : Permet de conserver des informations sur la
            session de l’utilisateur
          </li>
        </ul>
        <h3>Droit d’accès, de rectification et de suppression</h3>
        <p>
          Vous avez le droit d’accéder, de rectifier et de supprimer vos données
          personnelles. Vous pouvez exercer ce droit en nous contactant par mail
          à l’adresse suivante :
          <a href="mailto:rgpd@matchistador.com"> rgpd@matchistador.com</a>
        </p>
      </div>
      <div className="flex-v-c mt20 mb30">
        <Button
          onClick={handleClick}
          label="Retour"
          color={2}
          picture={ArrowCircleLeftIcon}
        />
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import axios from 'axios';
import { frontUrl } from './env_vars';
import { streaming_auth_service_url } from './env_vars';
import { cleanTitle } from './tracks';

const deezer = {
  clientId: '478182',
  redirectUri: `${frontUrl}/deezerconnect`,
  scopes: 'basic_access,email,listening_history,offline_access',

  makeConnectUrl: () => {
    const url = `https://connect.deezer.com/oauth/auth.php?app_id=${deezer.clientId}&redirect_uri=${deezer.redirectUri}&perms=${deezer.scopes}`;
    return url;
  },

  callApiByMatchistador: async (url) => {
    if (url) {
      const query = encodeURIComponent(url);
      const response = await axios.get(
        `${streaming_auth_service_url}/deezer/callapi?url=${query}`
      );
      return response;
    }
  },

  //////////////////////////////////////////////////////////////////////////////////////////////
  getMyInfo: async () => {
    const token = localStorage.getItem('streaming_access_token');
    const response = await deezer.callApiByMatchistador(
      'https://api.deezer.com/user/me?access_token=' + token
    );

    console.log('GMIFD', response.data);
    const data = {
      name: response.data.name,
      email: response.data.email,
      pictureUrl: response.data.picture_medium
        ? response.data.picture_medium
        : '',
    };
    return data;
  },

  getMyTracksFromDeezer: async (next) => {
    console.log('NEXT', next);
    const token = localStorage.getItem('streaming_access_token');
    let fetchUrl = next
      ? next
      : 'https://api.deezer.com/user/me/tracks?access_token=' + token;

    console.log('FETCHURL', fetchUrl);
    const response = await deezer.callApiByMatchistador(fetchUrl);
    console.log('getMyTracksFromDeezer', response.data.data);
    return response.data;
  },

  getMyHistoryFromDeezer: async () => {
    const token = localStorage.getItem('streaming_access_token');
    let fetchUrl =
      'https://api.deezer.com/user/me/history?access_token=' + token;

    const response = await deezer.callApiByMatchistador(fetchUrl);
    console.log('getMyHistoryFromDeezer', response.data.data);
    return response.data;
  },

  getMyTopTracksFromDeezer: async () => {
    const token = localStorage.getItem('streaming_access_token');
    let fetchUrl =
      'https://api.deezer.com/user/me/charts?access_token=' + token;

    const response = await deezer.callApiByMatchistador(fetchUrl);
    console.log('getMyHistoryFromDeezer', response.data.data);
    return response.data;
  },

  parseToApi: (tracks, config) => {
    const parsedTracks = tracks.map((track) => ({
      artist: track.artist.name,
      name: cleanTitle(track.title),
      album: track.album.title,
      popularity: Math.floor(track.rank / 10000).toString(),
      spotifyId: null,
      deezerId: track.id.toString(),
      pictureUrl: track.album.cover_medium ? track.album.cover_medium : '',
    }));

    return parsedTracks;
  },
};

export default deezer;

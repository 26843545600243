import * as ac from './actions';

const initialState = {
  user: {
    streamingPlatform: null,
    superTracks: [],
    tracks: [],
    totalTracksLength: '',
    token: null,
    isAuthStreamingPlatform: false,
    isAuthApi: false,
    hasLogout: false,
    musicDescription: {
      searchinputValue: '',
      searchResults: [],
      selectedTrackIndex: null,
      selectedTrack: null,
      selectedTheme: null,
      themes: [],
      isLoading: false,
      total: 0,
    },
    userData: {
      gender: '',
      orientation: '',
      positionLat: 0,
      positionLng: 0,
      city: '',
      birthdate: '',
      name: '',
      description: '',
      pictureUrl: null,
    },
    matchs: {
      all: {
        items: [],
        total: '',
      },
      last: {
        items: [],
        total: '',
      },
      best: {
        items: [],
        total: '',
      },
      favYou: {
        items: [],
        total: '',
      },
      fav: {
        items: [],
        total: '',
      },
    },
  },
  matchToDisplay: {
    match: null,
    tracks: {
      items: [],
      total: 0,
    },
    artists: [],
  },
  login: {
    sucess: false,
  },
  signin: {
    step: 1,
    title: '',
    state_title: 'Analyse de vos habitudes musicales',
    state_subtitle: null,
    state_message: null,
  },
  music_sync: {
    message: '',
    progress: 0,
    progressTracks: [],
    tracks_qty: 0,
    isDone: true,
    isSynced: false,
    tracksToSync: [],
  },
  messages: {
    errors: [],
    info: '',
  },

  loading: false,
  specificLoadings: {
    musicDescription: false,
  },
  notifications: {
    show: false,
  },
  alert: {
    show: false,
    message: '',
  },
  chat: {
    inputValue: '',
    conversations: {
      items: [],
      total: 0,
    },
    totalUnread: 0,
    conversationToDisplay: {
      inputValue: '',
      messages: [],
      conversation: {
        blockedByUserId: null,
      },
    },
  },
  search: {
    page: 1,
    results: {
      items: [],
      total: 0,
    },
  },
  oneSecond: {
    current: null,
    lasts: [],
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ac.LOGOUT:
      localStorage.clear();
      return {
        ...state,
        user: {
          ...initialState.user,
          hasLogout: true,
        },
      };

    case ac.SET_USER_CREDENTIALS:
      return {
        ...state,
        user: {
          ...state.user,
          userCredentials: action.userCredentials,
        },
      };
    case ac.SET_USER_STREAMING_PLATFORM:
      return {
        ...state,
        user: {
          ...state.user,
          streamingPlatform: action.platform,
        },
      };
    case ac.GET_USER_INFO_ERROR:
      return {
        ...state,
        user: {
          ...state.user,
          streaming_platform: '',
        },
      };
    case ac.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.userInfo.email,
          name: action.userInfo.name,
          pictureUrl: action.userInfo.pictureUrl,
        },
      };
    case ac.SET_USER_TOKEN:
      return {
        ...state,
        user: {
          ...state.user,
          token: action.token,
        },
      };
    case ac.SET_USER_ISAUTH_STREAMING_PLATFORM:
      return {
        ...state,
        user: {
          ...state.user,
          isAuthStreamingPlatform: action.bool,
        },
      };
    case ac.SET_USER_ISAUTH_API:
      return {
        ...state,
        user: {
          ...state.user,
          hasLogout: false,
          isAuthApi: action.bool,
        },
      };
    case ac.GET_USER_TRACKS_SET_MESSAGE:
      return {
        ...state,
        signin: {
          ...state.signin,
          state_message: action.message,
        },
      };
    case ac.SET_USER_SUPER_TRACKS:
      return {
        ...state,
        user: {
          ...state.user,
          superTracks: action.tracks,
        },
      };
    case ac.SET_USER_TRACKS:
      return {
        ...state,
        user: {
          ...state.user,
          tracks: action.tracks,
        },
      };
    case ac.SET_USER_TOTAL_TRACKS_LENGTH:
      return {
        ...state,
        user: {
          ...state.user,
          totalTracksLength: action.length,
        },
      };
    case ac.SET_SIGNIN_STEP_NEXT:
      return {
        ...state,
        signin: {
          ...state.signin,
          step: state.signin.step + 1,
        },
        messages: {
          ...state.messages,
          errors: [],
        },
      };
    case ac.SET_SIGNIN_STEP_BACK:
      return {
        ...state,
        signin: {
          ...state.signin,
          step: state.signin.step - 1,
        },
        messages: {
          ...state.messages,
          errors: [],
        },
      };
    case ac.SET_USER_GENDER:
      return {
        ...state,
        user: {
          ...state.user,
          userData: {
            ...state.user.userData,
            gender: action.gender,
          },
        },
      };
    case ac.SET_USER_ORIENTATION:
      return {
        ...state,
        user: {
          ...state.user,
          userData: {
            ...state.user.userData,
            orientation: action.orientation,
          },
        },
      };
    case ac.SET_USER_SPECIFIC_VALUE:
      return {
        ...state,

        user: {
          ...state.user,
          [action.target]: action.value,
        },
      };
    case ac.SET_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          userData: {
            ...state.user.userData,
            ...action.userData,
          },
        },
      };
    case ac.SET_USER_DATA_SPECIFIC_VALUE: {
      return {
        ...state,
        user: {
          ...state.user,
          userData: {
            ...state.user.userData,
            [action.target]: action.value,
          },
        },
      };
    }
    case ac.SET_USER_POINTS: {
      return {
        ...state,
        user: {
          ...state.user,
          points: action.points,
        },
      };
    }
    case ac.SET_SIGNIN_TITLE:
      return {
        ...state,
        signin: {
          ...state.signin,
          title: action.title,
        },
      };
    case ac.SET_ERRORS:
      return {
        ...state,
        messages: {
          ...state.messages,
          errors: action.errors,
        },
      };
    case ac.SET_INFO_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          info: action.message,
        },
      };
    case ac.SET_DESC_SEARCHINPUT_VALUE:
      return {
        ...state,
        user: {
          ...state.user,
          musicDescription: {
            ...state.user.musicDescription,
            searchinputValue: action.value,
          },
        },
      };
    case ac.SET_DESC_SEARCH_RESULTS:
      return {
        ...state,
        user: {
          ...state.user,
          musicDescription: {
            ...state.user.musicDescription,
            searchResults: action.results,
          },
        },
      };
    case ac.SET_DESC_SELECTED_TRACK_INDEX:
      return {
        ...state,
        user: {
          ...state.user,
          musicDescription: {
            ...state.user.musicDescription,
            selectedTrackIndex: action.index,
            selectedTrack:
              state.user.musicDescription.searchResults[action.index],
          },
        },
      };
    case ac.SET_DESC_PROFILE_CATEGORIES:
      return {
        ...state,
        user: {
          ...state.user,
          musicDescription: {
            ...state.user.musicDescription,
            categories: action.categories,
          },
        },
      };
    case ac.SET_MUSICDESCS_TOTAL:
      return {
        ...state,
        user: {
          ...state.user,
          musicDescription: {
            ...state.user.musicDescription,
            total: action.total,
          },
        },
      };
    case ac.SET_MATCH_PROFILE_THEMES:
      return {
        ...state,
        userMatch: {
          ...state.userMatch,
          match: {
            ...state.userMatch.match,
            themes: action.themes,
          },
        },
      };
    case ac.SET_DESC_SELECTED_CATEGORY:
      return {
        ...state,
        user: {
          ...state.user,
          musicDescription: {
            ...state.user.musicDescription,
            selectedCategory: action.category,
          },
        },
      };
    case ac.SET_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      };
    case ac.SET_SPECIFIC_LOADING:
      return {
        ...state,
        specificLoadings: {
          ...state.specificLoadings,
          [action.component]: action.bool,
        },
      };
    case ac.SET_USER_MATCHS:
      return {
        ...state,
        user: {
          ...state.user,
          matchs: {
            ...state.user.matchs,
            [action.key]: action.matchs,
          },
        },
      };
    case ac.SET_MATCH_TO_DISPLAY: {
      return {
        ...state,
        matchToDisplay: {
          ...state.matchToDisplay,
          match: action.match,
        },
      };
    }
    case ac.SET_MATCH_ISFAV: {
      return {
        ...state,
        matchToDisplay: {
          ...state.matchToDisplay,
          isFav: action.config.isFav,
          hasFavMe: action.config.hasFavMe,
        },
      };
    }
    case ac.SET_MATCH_TO_DISPLAY_TRACKS: {
      return {
        ...state,
        matchToDisplay: {
          ...state.matchToDisplay,
          tracks: action.tracks,
        },
      };
    }
    case ac.SET_MATCH_TO_DISPLAY_ARTISTS: {
      return {
        ...state,
        matchToDisplay: {
          ...state.matchToDisplay,
          artists: action.artists,
        },
      };
    }
    case ac.CLEAR_MATCHS: {
      return {
        ...state,
        user: {
          ...state.user,
          matchs: {
            ...initialState.user.matchs,
          },
        },
      };
    }

    case ac.LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          success: true,
        },
      };
    case ac.LOGIN_FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          success: false,
        },
      };
    case ac.LOGIN_RESET:
      return {
        ...state,
        login: {
          ...state.login,
          success: null,
        },
      };
    case ac.SET_SYNCED:
      return {
        ...state,
        music_sync: {
          ...state.music_sync,
          isSynced: action.bool,
        },
      };
    case ac.SET_MUSICSYNC_PROGRESS:
      return {
        ...state,
        music_sync: {
          ...state.music_sync,
          progress: action.progress,
          tracks_qty: action.tracks_qty,
          isDone: action.progress < 100 ? false : true,
        },
      };
    case ac.SET_MUSICSYNC_MESSAGE:
      return {
        ...state,
        music_sync: {
          ...state.music_sync,
          message: action.message,
        },
      };
    case ac.SET_MUSICSYNC_PROGRESS_TRACKS:
      return {
        ...state,
        music_sync: {
          ...state.music_sync,
          progressTracks: action.track,
        },
      };
    case ac.SET_TRACKS_TO_SYNC:
      return {
        ...state,
        music_sync: {
          ...state.music_sync,
          tracksToSync: action.tracks,
        },
      };
    case ac.SHOW_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          show: action.bool,
        },
      };
    case ac.SET_CONVERSATIONS_LIST:
      return {
        ...state,
        chat: {
          ...state.chat,
          conversations: action.conversations,
        },
      };
    case ac.CONVERSATION_ADD_MESSAGE:
      return {
        ...state,
        chat: {
          ...state.chat,
          conversationToDisplay: {
            ...state.chat.conversationToDisplay,
            messages: [
              {
                author: '/users/' + localStorage.getItem('userId'),
                content: action.content,
              },
              ...state.chat.conversationToDisplay.messages,
            ],
          },
        },
      };
    case ac.SET_CONVERSATION_TO_DISPLAY:
      return {
        ...state,
        chat: {
          ...state.chat,
          conversationToDisplay: {
            ...state.chat.conversationToDisplay,
            conversation: action.conversation,
          },
        },
      };
    case ac.SET_CONVERSATION_TO_DISPLAY_MESSAGES:
      return {
        ...state,
        chat: {
          ...state.chat,
          conversationToDisplay: {
            ...state.chat.conversationToDisplay,
            messages: action.messages,
          },
        },
      };
    case ac.CONVERSATION_SET_INPUT_VALUE:
      return {
        ...state,
        chat: {
          ...state.chat,
          conversationToDisplay: {
            ...state.chat.conversationToDisplay,
            inputValue: action.value,
          },
        },
      };
    case ac.SET_TOTAL_UNREAD_CONVERSATIONS:
      return {
        ...state,
        chat: {
          ...state.chat,
          totalUnread: action.total,
        },
      };
    case ac.SET_SEARCH_PAGE:
      return {
        ...state,
        search: {
          ...state.search,
          page: action.page,
        },
      };
    case ac.SET_USER_MATCHS_SEARCH_RESULTS:
      return {
        ...state,
        search: {
          ...state.search,
          results: action.matchs,
        },
      };
    case ac.SHOW_ALERT:
      return {
        ...state,
        alert: {
          ...state.alert,
          show: true,
        },
      };
    case ac.HIDE_ALERT:
      return {
        ...state,
        alert: {
          ...state.alert,
          show: false,
        },
      };
    case ac.SET_ALERT_MESSAGE:
      return {
        ...state,
        alert: {
          ...state.alert,
          message: action.message,
          alertType: action.alertType,
        },
      };
    case ac.SET_ONESECOND_CURRENT:
      return {
        ...state,
        oneSecond: {
          ...state.oneSecond,
          current: action.current,
        },
      };
    case ac.SET_ONESECOND_LASTS:
      return {
        ...state,
        oneSecond: {
          ...state.oneSecond,
          lasts: action.lasts,
        },
      };

    default:
      return state;
  }
};

export default reducer;

import dayjs from 'dayjs';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isYesterday);
dayjs.extend(isToday);

export const dateDisplay = (messageDate) => {
  const date = dayjs(messageDate);

  if (date.isToday()) {
    return "Aujourd'hui à " + date.format('HH:mm');
  } else if (date.isYesterday()) {
    return 'Hier à ' + date.format('HH:mm');
  } else return date.format('DD/MM/YYYY à HH:mm');
};

import './Header.scss';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { getNotifs } from '../store/selectors';

import musicalyLogo from '../assets/logodegrade2.png';
import logomin from '../assets/logomin.png';
import { FaBell } from 'react-icons/fa';
import { FaHeartbeat } from 'react-icons/fa';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { IoChatbubbleEllipses } from 'react-icons/io5';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

import SvgIcon from '@material-ui/core/SvgIcon';

import NotificationsContainer from './Notifications/NotificationsContainer';
import Button from './Button';

import {
  showNotifications,
  getTotalUnreadConversations,
  getMusicDescsTotal,
  getUserData,
} from '../store/actions';
import Alert from './Notifications/Alert';
import { Fade } from 'react-reveal';

const mapStateToProps = (state) => ({
  userName: state.user.userData.name,
  showNotifications: state.notifications.show,
  notificationsItems: getNotifs(state),
  totalUnreadConversations: state.chat.totalUnread,
  userPoints: state.user.points,
  userIsPremium:
    state.user.role === 'premium' || state.user.role === 'superuser'
      ? true
      : false,
});

const mapDispatchToProps = (dispatch) => ({
  SetShowNotifications: (bool) => dispatch(showNotifications(bool)),
  getTotalUnreadConversations: () => dispatch(getTotalUnreadConversations()),
  getMusicDescsTotal: () => dispatch(getMusicDescsTotal()),
  getUserData: () => dispatch(getUserData()),
});

const Header = ({
  userName,
  SetShowNotifications,
  showNotifications,
  notificationsItems,
  totalUnreadConversations,
  getTotalUnreadConversations,
  getMusicDescsTotal,
  userPoints,
  userIsPremium,
}) => {
  useEffect(() => {
    getMusicDescsTotal();

    const timer = setInterval(() => {
      getTotalUnreadConversations();
    }, 30000);
    return () => clearInterval(timer);
  }, []);

  const navigate = useNavigate();
  const goTo = (path) => navigate(path);
  const toggleNotifications = () => {
    showNotifications
      ? SetShowNotifications(false)
      : SetShowNotifications(true);
  };
  return (
    <>
      <Alert />
      <div className="Header">
        <div className="Header__container">
          <NotificationsContainer />

          <img
            src={musicalyLogo}
            alt=""
            className="Header__logo"
            onClick={() => goTo('/home')}
          />
          <img
            src={logomin}
            alt=""
            className="Header__logomin"
            onClick={() => goTo('/home')}
          />
          <div className="Header__toolbar">
            <Fade bottom spy={userPoints}>
              <div
                className="Header__toolbar__points"
                onClick={!userIsPremium ? () => navigate('/pricing') : null}
              >
                <FaHeartbeat className="mr5" />
                {userIsPremium ? (
                  <SvgIcon component={AllInclusiveIcon} />
                ) : (
                  <>{userPoints}</>
                )}
              </div>
            </Fade>
            <Button
              picture={AccountCircleIcon}
              reverse
              label={userName}
              onClick={() => goTo('/account')}
            />
            <div>
              <Button
                component={<IoChatbubbleEllipses />}
                onClick={() => goTo('/chat')}
                badge={totalUnreadConversations}
              />
              <Button
                component={<FaBell />}
                onClick={toggleNotifications}
                badge={notificationsItems.length}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

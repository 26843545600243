import './Legal.scss';
import logo from '../../assets/logodegrade2.png';

import './Legal.scss';

import React from 'react';
import { useNavigate } from 'react-router';

import Button from '../../components/Button';

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { NavLink } from 'react-router-dom';

const Legal = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  return (
    <div className="main-container">
      <div className="Legal">
        <img src={logo} alt="Logo Banjo" className="Legal__logo" />

        <h2>Mentions légales</h2>
        <p className="mb20 mt20">
          Ce site est exploité par la société Matchistador SAS, SAS au capital
          de 1.000€ immatriculée sous le numéro 909 871 584 au RCS de ROUEN,
          dont le siège social est situé 26 rue Césaire Levillain 76230
          Bois-Guillaume, et représentée par son Président, M. Jonathan Luquet.
        </p>
        <p>
          L'hébergement du site est assuré par :
          <ul>
            <li>
              OVH SAS dont le siège social est situé au 2 rue Kellermann - 59100
              Roubaix - France
            </li>
            <li>
              Clever Cloud SAS dont le siège social est situé au 3 rue de
              l’Allier, 44000 Nantes - France.
            </li>
          </ul>
        </p>
        <p className="mb20">
          Pour + d'informations, veuillez consulter les sections suivantes :
        </p>
        <ul className="bold underline ">
          <li className="">
            <NavLink to="/about/privacy">Politique de confidentialité</NavLink>
          </li>
          <li>
            <NavLink to="/about/termsofuse">
              Conditions générales d'utilisation
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="flex-v-c mt20 mb30">
        <Button
          onClick={handleClick}
          label="Retour"
          color={2}
          picture={ArrowCircleLeftIcon}
        />
      </div>
    </div>
  );
};

export default Legal;

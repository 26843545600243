import { frontUrl } from './env_vars';
import { cleanTitle } from './tracks';

const spotify = {
  clientId: 'cd47067c9a6743619eb7c24d6b1e4c3d',
  redirectUri: `${frontUrl}/spotifyconnect`,
  redirectUriMobile: `banjomeet://spotifyconnect`,
  scopes: [
    'user-read-email',
    'user-read-private',
    'playlist-read-private',
    'user-library-read',
    'user-top-read',
    'user-read-recently-played',
  ],

  makeConnectUrl: () => {
    const url =
      'https://accounts.spotify.com/authorize' +
      '?response_type=code' +
      '&client_id=' +
      spotify.clientId +
      (spotify.scopes ? '&scope=' + encodeURIComponent(spotify.scopes) : '') +
      '&redirect_uri=' +
      encodeURIComponent(spotify.redirectUri);
    return url;
  },

  makeConnectUrlMobile: () => {
    const url =
      'https://accounts.spotify.com/authorize' +
      '?response_type=code' +
      '&client_id=' +
      spotify.clientId +
      (spotify.scopes ? '&scope=' + encodeURIComponent(spotify.scopes) : '') +
      '&redirect_uri=' +
      encodeURIComponent(spotify.redirectUriMobile);
    return url;
  },

  getMyInfo: async () => {
    const token = localStorage.getItem('streaming_access_token');
    let response = await fetch('https://api.spotify.com/v1/me', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    response = await response.json();
    console.log('GMIFS', response);
    const data = {
      name: response.display_name,
      email: response.email,
      pictureUrl:
        response.images[0] && response.images[0].url
          ? response.images[0].url
          : '',
    };
    return data;
  },

  getMyTopTracksFromSpotify: async (term) => {
    let fetchUrl = `https://api.spotify.com/v1/me/top/tracks?time_range=${term}&limit=50`;
    try {
      let response = await fetch(fetchUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer ' + localStorage.getItem('streaming_access_token'),
        },
      });
      response = await response.json();

      console.log('getMyTopTracksFromSpotify', response);
      return response;
    } catch (error) {
      return console.error(error);
    }
  },

  getMyTracksFromSpotify: async (next) => {
    let fetchUrl = next
      ? next
      : 'https://api.spotify.com/v1/me/tracks?limit=50';

    let response = await fetch(fetchUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('streaming_access_token'),
      },
    });
    response = await response.json();
    console.log('getMyTracksFromSpotify', response);
    return response;
  },

  parseToApi: (tracks, config) => {
    //pour spotify tracks = response.items
    //attention, spotify ne renvoie pas la même structure selon toptracks ou alltracks, d'où:
    console.log('parseToApi params', { tracks, config });
    let parsedTracks = [];
    if (config.areTopTracks === true) {
      parsedTracks = tracks.map((track) => ({
        artist: track.artists[0].name,
        name: cleanTitle(track.name),
        album: track.album.name,
        popularity: track.popularity.toString(),
        spotifyId: track.id,
        deezerId: null,
        pictureUrl:
          track.album.images && track.album.images
            ? track.album.images[1].url
            : '',
      }));
    } else {
      parsedTracks = tracks.map((track) => ({
        artist: track.track.artists[0].name,
        name: cleanTitle(track.track.name),
        album: track.track.album.name,
        popularity: track.track.popularity.toString(),
        spotifyId: track.track.id,
        deezerId: null,
        pictureUrl:
          track.track.album &&
          track.track.album.images &&
          track.track.album.images[1] &&
          track.track.album.images[1].url
            ? track.track.album.images[1].url
            : '',
      }));
    }
    return parsedTracks;
  },
};

export default spotify;

export const getNotifs = (state) => {
  const notifs = [];
  if (state.chat.totalUnread > 0) {
    notifs.push({
      label: 'Tu as de nouveaux messages',
      color: 1,
      type: 'chat',
      buttonLabel: 'Chat',
      route: '/chat',
    });
  }
  if (!state.user.userData.pictureUrl) {
    notifs.push({
      label: 'Ajoute une photo à ton profil',
      color: 2,
      type: 'alert',
      buttonLabel: 'Mon profil',
      route: '/account',
    });
  }
  if (!state.user.userData.description) {
    notifs.push({
      label: 'Ecris un petit mot sur toi',
      color: 2,
      type: 'alert',
      buttonLabel: 'Mon profil',
      route: '/account',
    });
  }
  if (state.user.musicDescription.total < 4) {
    notifs.push({
      label: 'Complète ta description musicale',
      color: 2,
      type: 'alert',
      buttonLabel: 'Mon profil',
      route: '/account',
    });
  }
  return notifs;
};

export const getProfileCompletion = (state) => {
  let profileCompletion = 40;

  if (state.user.userData.pictureUrl) {
    profileCompletion += 20;
  }
  if (state.user.userData.description) {
    profileCompletion += 20;
  }
  if (state.user.musicDescription.total > 0) {
    profileCompletion += 20;
  }

  return profileCompletion;
};

export const getConversationToDisplayUser = (state) => {
  if (state.chat.conversationToDisplay.conversation) {
    const user =
      state.chat.conversationToDisplay.conversation.participants.filter(
        (item) => item.user.id !== localStorage.getItem('userId')
      );
    console.log('conversationUser : ', user[0]);
    return user[0];
  }
};

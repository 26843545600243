import spotify from '../services/spotify';
import deezer from '../services/deezer';
import api from '../services/api';
import apiParser from '../utils/apiParser';
import { randomElement } from '../utils/sort';

// Action Creators

//// MAIN
export const SET_LOADING = 'SET_LOADING';

//// LOADINGS
export const SET_SPECIFIC_LOADING = 'SET_SPECIFIC_LOADING';

//// LOGIN
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_RESET = 'LOGIN_RESET';

//// USER
export const GET_USER_TRACKS_SET_MESSAGE = 'GET_USER_TRACKS_SET_MESSAGE';
export const SET_USER_SUPER_TRACKS = 'SET_USER_SUPER_TRACKS';
export const SET_USER_TRACKS = 'SET_USER_TRACKS';
export const SET_USER_TOTAL_TRACKS_LENGTH = 'SET_USER_TOTAL_TRACKS_LENGTH';
export const SET_USER_STREAMING_PLATFORM = 'SET_USER_STREAMING_PLATFORM';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR';
export const SET_USER_SPECIFIC_VALUE = 'SET_USER_SPECIFIC_VALUE';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_USER_ISAUTH_STREAMING_PLATFORM =
  'SET_USER_ISAUTH_STREAMING_PLATFORM';
export const SET_USER_ISAUTH_API = 'SET_USER_ISAUTH_API';
export const CHECK_USER_LOGIN = 'CHECK_USER_LOGIN';
export const SET_USER_CREDENTIALS = 'SET_USER_CREDENTIALS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_DATA_SPECIFIC_VALUE = 'SET_USER_DATA_SPECIFIC_VALUE';
export const SET_USER_POINTS = 'SET_USER_POINTS';

export const LOGOUT = 'LOGOUT';

//// SIGNIN
export const SET_SIGNIN_STEP_NEXT = 'SET_SIGNIN_STEP_NEXT';
export const SET_SIGNIN_STEP_BACK = 'SET_SIGNIN_STEP_BACK';
export const SET_USER_GENDER = 'SET_USER_GENDER';
export const SET_USER_ORIENTATION = 'SET_USER_ORIENTATION';
export const SET_SIGNIN_TITLE = 'SET_SIGNIN_TITLE';

//// SYNC
export const SET_SYNCED = 'SET_SYNCED';
export const SET_TRACKS_TO_SYNC = 'SET_TRACKS_TO_SYNC';
export const SET_MUSICSYNC_MESSAGE = 'SET_MUSICSYNC_MESSAGE';
export const SET_MUSICSYNC_PROGRESS = 'SET_MUSICSYNC_PROGRESS';
export const SET_MUSICSYNC_PROGRESS_TRACKS = 'SET_MUSICSYNC_PROGRESS_TRACK';

//// ERRORS
export const SET_ERRORS = 'SET_ERRORS';
export const SET_INFO_MESSAGE = 'SET_INFO_MESSAGE';

//// MUSICDESC
export const SET_DESC_SEARCHINPUT_VALUE = 'SET_DESC_SEARCHINPUT_VALUE';
export const SET_DESC_SEARCH_RESULTS = 'SET_DESC_SEARCH_RESULTS';
export const SET_DESC_SELECTED_TRACK_INDEX = 'SET_DESC_SELECTED_TRACK_INDEX';
export const SET_DESC_PROFILE_CATEGORIES = 'SET_DESC_PROFILE_CATEGORIES';
export const SET_DESC_SELECTED_CATEGORY = 'SET_DESC_SELECTED_CATEGORY';
export const SET_MUSICDESCS_TOTAL = 'SET_MUSICDESCS_TOTAL';

//// MATCHS
export const SET_USER_MATCHS = 'SET_USER_MATCHS';
export const SET_MATCH_TO_DISPLAY = 'SET_MATCH_TO_DISPLAY';
export const SET_MATCH_TO_DISPLAY_TRACKS = 'SET_MATCH_TO_DISPLAY_TRACKS';
export const SET_MATCH_TO_DISPLAY_ARTISTS = 'SET_MATCH_TO_DISPLAY_ARTISTS';
export const SET_USER_MATCH_ISFAV = 'SET_USER_MATCH_ISFAV';
export const SET_MATCH_PROFILE_THEMES = 'SET_MATCH_PROFILE_THEMES';
export const CLEAR_MATCHS = 'CLEAR_MATCHS';

//// FAV
export const SET_MATCH_ISFAV = 'SET_MATCH_ISFAV';

//// NOTIFICATIONS
export const SHOW_NOTIFICATIONS = 'SHOW_NOTIFICATIONS';

//// ALERT
export const SHOW_ALERT = 'SHOW_ALERT';
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE';
export const HIDE_ALERT = 'HIDE_ALERT';

//// CHAT
export const SET_CONVERSATIONS_LIST = 'SET_CONVERSATIONS_LIST';
export const CONVERSATION_ADD_MESSAGE = 'CONVERSATION_ADD_MESSAGE';
export const CONVERSATION_SET_INPUT_VALUE = 'CONVERSATION_SET_INPUT_VALUE';
export const SET_CONVERSATION_TO_DISPLAY = 'SET_CONVERSATION_TO_DISPLAY';
export const SET_CONVERSATION_TO_DISPLAY_MESSAGES =
  'SET_CONVERSATION_TO_DISPLAY_MESSAGES';
export const SET_TOTAL_UNREAD_CONVERSATIONS = 'SET_TOTAL_UNREAD_CONVERSATIONS';

//// SEARCH
export const SET_SEARCH_PAGE = 'SET_SEARCH_PAGE';
export const SET_USER_MATCHS_SEARCH_RESULTS = 'SET_USER_MATCHS_SEARCH_RESULTS';

//// ONESECOND
export const SET_ONESECOND_CURRENT = 'SET_ONESECOND_CURRENT';
export const SET_ONESECOND_LASTS = 'SET_ONESECOND_LASTS';

// ACTIONS

// LOADINGS

export const setLoading = (bool) => ({
  type: SET_LOADING,
  isLoading: bool,
});

export const setSpecificLoading = (component, bool) => ({
  type: SET_SPECIFIC_LOADING,
  component,
  bool,
});

// LOGIN
export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

export const loginFailure = () => ({
  type: LOGIN_FAILURE,
});
export const loginReset = () => ({
  type: LOGIN_RESET,
});

// USER
export const deleteMe = () => {
  return async (dispatch, getState) => {
    await api.deleteMe();
    dispatch(logout());
  };
};

export const getUserInfoFromStreamingPlatform = () => {
  return async (dispatch, getState) => {
    const streamingPlatform = getState().user.streamingPlatform;
    try {
      if (streamingPlatform === 'spotify') {
        const userInfo = await spotify.getMyInfo();

        dispatch(getUserInfoSuccess(userInfo));
        dispatch(setUserDataSpecificValue('pictureUrl', userInfo.pictureUrl));
        dispatch(setUserIsAuthStreamingPlatform(true));
      }
      if (streamingPlatform === 'deezer') {
        const userInfo = await deezer.getMyInfo();

        dispatch(getUserInfoSuccess(userInfo));
        dispatch(setUserDataSpecificValue('pictureUrl', userInfo.pictureUrl));
        dispatch(setUserIsAuthStreamingPlatform(true));
      }
    } catch (error) {
      dispatch(getUserInfoError());
      dispatch(setUserIsAuthStreamingPlatform(false));
    }
  };
};

export const getUserInfoSuccess = (userInfo) => ({
  type: GET_USER_INFO_SUCCESS,
  userInfo,
});

export const getUserInfoError = () => ({ type: GET_USER_INFO_ERROR });

export const setUserSpecificValue = (target, value) => ({
  type: SET_USER_SPECIFIC_VALUE,
  target,
  value,
});

export const setUserToken = (token) => ({ type: SET_USER_TOKEN, token });

export const setUserIsAuthStreamingPlatform = (bool) => ({
  type: SET_USER_ISAUTH_STREAMING_PLATFORM,
  bool,
});

export const setUserIsAuthApi = (bool) => ({ type: SET_USER_ISAUTH_API, bool });

export const logout = () => ({ type: LOGOUT });

export const setUserStreamingPlatform = (platform) => ({
  type: SET_USER_STREAMING_PLATFORM,
  platform,
});

export const registerUser = () => {
  return async (dispatch, getState) => {
    const user = {
      email: getState().user.email,
      streamingPlatform: getState().user.streamingPlatform,
      password: getState().user.password,
    };
    const response = await api.registerUser(user);
    dispatch(setUserIsAuthApi(true));
    return response.data;
  };
};

export const createUserData = () => {
  return async (dispatch, getState) => {
    const user = getState().user;
    const response = await api.createUserData(apiParser.userData(user));
    return response.data;
  };
};

export const loginUser = () => {
  return async (dispatch, getState) => {
    const user = {
      email: getState().user.email,
      password: getState().user.password,
    };
    try {
      dispatch(setLoading(true));
      const response = await api.login(user);
      dispatch(setUserToken(response.data.token));
      dispatch(setUserIsAuthApi(true));
      dispatch(loginSuccess());
      dispatch(setLoading(false));
      dispatch(setErrors([]));

      return response.data;
    } catch (error) {
      dispatch(setLoading(false));

      dispatch(logout);
      dispatch(loginFailure());
      if (error && error.response && error.response.status === 401) {
        dispatch(setErrors(['Email ou mot de passe invalide']));
      } else {
        dispatch(setErrors(['Une erreur est survenue']));
      }
    }
  };
};

// export const checkUserLogin = () => {
//   // OBSOLETE
//   return async (dispatch) => {
//     await api.checkUserLogin();
//     dispatch(getUserData());
//     dispatch(loginReset()); // reset login state pour éviter boucle infinie si l'user se déconnecte et revient sur la page de login
//     dispatch(setUserIsAuthApi(true));
//   };
// };

export const setUserCredentials = (userCredentials) => ({
  type: SET_USER_CREDENTIALS,
  userCredentials,
});

export const patchUser = () => {
  return async (dispatch, getState) => {
    const user = {};
    user.email = getState().user.email;
    user.password = getState().user.password;
    const response = await api.updateUser(user);
    return response.data;
  };
};

export const patchUserData = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));

    const {
      gender,
      orientation,
      positionLat,
      positionLng,
      city,
      birthdate,
      name,
      description,
      pictureUrl,
      searchDistance,
      searchAgeDifference,
      oneSecondStatus,
      oneSecondAttempts,
      oneSecondTime,
    } = getState().user.userData;

    const response = await api.updateUserData({
      gender,
      orientation,
      positionLat,
      positionLng,
      city,
      birthdate,
      name,
      description,
      pictureUrl,
      searchDistance,
      searchAgeDifference,
      oneSecondStatus,

      oneSecondAttempts,
      oneSecondTime,
    });
    // dispatch(getUserData());
    dispatch(setLoading(false));

    return response.data;
  };
};

export const getUserData = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));

    const response = await api.getUserData();
    console.log(response.data);
    dispatch(
      setUserSpecificValue('streamingPlatform', response.data.streamingPlatform)
    );
    dispatch(setUserSpecificValue('email', response.data.email));
    dispatch(setUserSpecificValue('id', response.data.id));
    dispatch(setUserSpecificValue('role', response.data.role));
    dispatch(setUserSpecificValue('points', response.data.points));
    dispatch(setUserSpecificValue('newMessages', response.data.newMessages));

    dispatch(setUserData(response.data.userData));
    dispatch(loginReset()); // reset login state pour éviter boucle infinie si l'user se déconnecte et revient sur la page de login
    dispatch(setLoading(false));
  };
};

export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  userData,
});

export const setUserDataSpecificValue = (target, value) => ({
  type: SET_USER_DATA_SPECIFIC_VALUE,
  target,
  value,
});

// TRACKS
export const getUserTracksFromSpotify = (config) => {
  //exemple config = { limit: int } ** obligatoire
  return async (dispatch, getState) => {
    dispatch(setMusicSyncMessage('Récupération de tes titres likés'));
    const allTracks = [];
    //1 Récupérer les top tracks long moyen et court terme
    const topTracks_LT = await spotify.getMyTopTracksFromSpotify('long_term');
    const topTracks_MT = await spotify.getMyTopTracksFromSpotify('medium_term');
    const topTracks_ST = await spotify.getMyTopTracksFromSpotify('short_term');

    // dispatch(setUserSuperTracks(topTracks10Limit));
    const topTracks = [
      ...new Set([
        ...topTracks_LT.items,
        ...topTracks_MT.items,
        ...topTracks_ST.items,
      ]),
    ];

    allTracks.push(...spotify.parseToApi(topTracks, { areTopTracks: true }));
    console.log('toptracks parsés', allTracks);

    // BOUCLE POUR RECUPERER TOUS LES TRACKS DE SPOTIFY
    let responseFromSpotify = await spotify.getMyTracksFromSpotify();

    while (responseFromSpotify.next && allTracks.length < config.limit) {
      const tracks = spotify.parseToApi(responseFromSpotify.items, {
        areTopTracks: false,
      });
      //Eviter les doublons avec les top tracks
      tracks.forEach((track) => {
        if (
          allTracks.length < config.limit &&
          !allTracks.find(
            (allTracksItem) =>
              allTracksItem.artist === track.artist &&
              allTracksItem.name === track.name
          )
        ) {
          allTracks.push(track);
        } else {
          console.log('track déjà présent', track.name);
        }
      });
      responseFromSpotify = await spotify.getMyTracksFromSpotify(
        responseFromSpotify.next
      );
    }

    console.log('tracks parsés total', allTracks);
    dispatch(setTracksToSync(allTracks));
  };
};

export const getUserTracksFromDeezer = (config) => {
  //exemple config = { limit: int } ** obligatoire
  return async (dispatch, getState) => {
    dispatch(setMusicSyncMessage('Récupération de tes playlists'));
    const allTracks = [];

    //1 Récupérer les tracks dans l'history (pas de top tracks chez deezer)

    const historyResponse = await deezer.getMyHistoryFromDeezer();
    const historyTracks = deezer.parseToApi(historyResponse.data);
    allTracks.push(...historyTracks);

    const topTracksResponse = await deezer.getMyTopTracksFromDeezer();
    const topTracks = deezer.parseToApi(topTracksResponse.data);
    allTracks.push(...topTracks);

    console.log('historyTracks parsés', historyTracks.length);
    console.log('topTracks parsés', topTracks.length);

    //2 Récupérer les favs

    let responseFromDeezer = await deezer.getMyTracksFromDeezer();

    while (responseFromDeezer.next && allTracks.length < config.limit) {
      const tracks = deezer.parseToApi(responseFromDeezer.data);
      //Eviter les doublons avec les top tracks
      tracks.forEach((track) => {
        if (
          allTracks.length < config.limit &&
          !allTracks.find(
            (allTracksItem) =>
              allTracksItem.artist === track.artist &&
              allTracksItem.name === track.name
          )
        ) {
          allTracks.push(track);
        } else {
          console.log('track déjà présent', track.name);
        }
      });
      responseFromDeezer = await deezer.getMyTracksFromDeezer(
        responseFromDeezer.next
      );
    }

    console.log('tracks parsés total', allTracks);
    dispatch(setTracksToSync(allTracks));
  };
};

export const syncTracksToApi = (config) => {
  //config: { step: int, newUser: bool }
  return async (dispatch, getState) => {
    dispatch(setMusicSyncMessage('Synchronisation avec Banjo'));

    const tracks = getState().music_sync.tracksToSync;
    const totalTracksLength = tracks.length;
    const progressTracks = [];
    while (tracks.length > 0) {
      const toSend = tracks.splice(-config.step);

      if (tracks.length === 0) {
        console.log('last true', tracks.length);
        await api.syncTracks(toSend, { newUser: config.newUser, last: true }); //marquer le dernier paquet envoyé pour déclencher le calcul en back
      } else {
        console.log('last false', tracks.length);

        await api.syncTracks(toSend, { newUser: config.newUser });
      }
      console.log('reste à envoyer :', tracks.length);
      dispatch(
        setMusicSyncProgress(
          totalTracksLength - tracks.length,
          totalTracksLength
        )
      );
      progressTracks.push(randomElement(toSend));
      dispatch(setMusicSyncProgressTracks(progressTracks));
    }
    dispatch(setMusicSyncMessage('Terminé !'));
  };
};

export const getUserTracksSetMessage = (message) => ({
  type: GET_USER_TRACKS_SET_MESSAGE,
  message,
});

export const setUserSuperTracks = (tracks) => ({
  type: SET_USER_SUPER_TRACKS,
  tracks,
});

export const setUserTracks = (tracks) => ({
  type: SET_USER_TRACKS,
  tracks,
});

export const getUserTracksFromDb = ({ limit, page }) => {
  return async (dispatch) => {
    const response = await api.getUserTracks({ limit, page });
    dispatch(setUserTracks(response.data.items));
    dispatch(setUserTotalTracksLength(response.data.total));
  };
};

export const setUserTotalTracksLength = (length) => ({
  type: SET_USER_TOTAL_TRACKS_LENGTH,
  length,
});

export const setUserPoints = (points) => ({
  type: SET_USER_POINTS,
  points,
});

export const payByPoints = (points) => {
  return (dispatch, getState) => {
    const actualPoints = getState().user.points;
    const role = getState().user.role;
    if (role === 'user' && actualPoints > 0) {
      dispatch(setUserPoints(actualPoints - points));
    }
  };
};

//SIGNIN
export const setSigninStepNext = () => ({
  type: SET_SIGNIN_STEP_NEXT,
});

export const setSigninStepBack = () => ({
  type: SET_SIGNIN_STEP_BACK,
});

export const setUserGender = (gender) => ({
  type: SET_USER_GENDER,
  gender,
});

export const setUserOrientation = (orientation) => ({
  type: SET_USER_ORIENTATION,
  orientation,
});

export const setSigninTitle = (title) => ({
  type: SET_SIGNIN_TITLE,
  title,
});

//SYNC

export const setSynced = (bool) => ({
  type: SET_SYNCED,
  bool,
});

export const setTracksToSync = (tracks) => ({
  type: SET_TRACKS_TO_SYNC,
  tracks,
});

export const setMusicSyncMessage = (message) => ({
  type: SET_MUSICSYNC_MESSAGE,
  message,
});

export const setMusicSyncProgress = (progress, tracks_qty) => ({
  type: SET_MUSICSYNC_PROGRESS,
  progress: Math.floor((progress / tracks_qty) * 100),
});

export const setMusicSyncProgressTracks = (track) => ({
  type: SET_MUSICSYNC_PROGRESS_TRACKS,
  track,
});

//MESSAGES
export const setErrors = (errors) => ({
  type: SET_ERRORS,
  errors,
});

export const setInfoMessage = (message) => ({
  type: SET_INFO_MESSAGE,
  message,
});

//DESCRIPTION
export const setDescSearchinputValue = (value) => ({
  type: SET_DESC_SEARCHINPUT_VALUE,
  value,
});

export const setDescSearchResults = (results) => ({
  type: SET_DESC_SEARCH_RESULTS,
  results,
});

export const setDescSelectedTrackIndex = (index) => ({
  type: SET_DESC_SELECTED_TRACK_INDEX,
  index,
});

export const setDescProfileCategories = (categories) => ({
  type: SET_DESC_PROFILE_CATEGORIES,
  categories,
});

export const setMatchProfileThemes = (themes) => ({
  type: SET_MATCH_PROFILE_THEMES,
  themes,
});

export const searchDescUserTracks = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const searchValue = getState().user.musicDescription.searchinputValue;

    const response = await api.searchTracks(searchValue);
    dispatch(setLoading(false));
    dispatch(setDescSearchResults(response.data));
  };
};

export const setDescSelectedCategory = (category) => ({
  type: SET_DESC_SELECTED_CATEGORY,
  category,
});

export const getMatchProfileThemes = () => {
  return async (dispatch, getState) => {
    const response = await api.getProfileThemes();
    const profileThemes = response.data;
    const userId = getState().userMatch.user.id;
    if (profileThemes && profileThemes.length > 0) {
      await Promise.all(
        profileThemes.map(async (theme) => {
          const response = await api.getMatchProfileTracksByTheme(
            userId,
            theme.id
          );
          if (response.data.length > 0) {
            const track = response.data[0].track;
            const userProfileId = response.data[0].id;
            theme.track = track;
            theme.userProfileId = userProfileId;
            console.log(track);
          }
        })
      );
      return dispatch(setMatchProfileThemes(profileThemes));
    }
  };
};

export const getMusicDescsTotal = () => {
  return async (dispatch, getState) => {
    console.log('GET MUSIC DESCS TOTAL');
    const response = await api.getMusicDescs();
    const total = response.data.total;
    return dispatch(setMusicDescsTotal(total));
  };
};

export const getMusicDescCategories = () => {
  return async (dispatch, getState) => {
    const response = await api.getMusicDescCategories();
    const musicCategories = response;

    dispatch(getMusicDescsTotal());
    dispatch(setSpecificLoading('musicDescription', false));

    return dispatch(setDescProfileCategories(musicCategories));
  };
};

export const setMusicDescsTotal = (total) => ({
  type: SET_MUSICDESCS_TOTAL,
  total,
});

export const setTrackInMusicDescCategory = () => {
  return async (dispatch, getState) => {
    const trackId =
      getState().user.musicDescription.selectedTrack &&
      getState().user.musicDescription.selectedTrack.id;
    const musicDescCategoryId =
      getState().user.musicDescription.selectedCategory &&
      getState().user.musicDescription.selectedCategory.id;

    const response = await api.setTrackInMusicDescCategory({
      trackId,
      musicDescCategoryId,
    });

    console.log(response);
    dispatch(
      alertMessage({ message: 'Catégorie mise à jour', alertType: 'success' })
    );
  };
};

// MATCHS

export const setUserMatchsSearchResults = (matchs) => ({
  type: SET_USER_MATCHS_SEARCH_RESULTS,
  matchs,
});
export const setUserMatchs = (matchs, key) => ({
  type: SET_USER_MATCHS,
  matchs,
  key,
});

export const getUserMatchs = (limit, page) => {
  return async (dispatch) => {
    const matchs = await api.getAllMatchsFromUser({
      limit: limit,
      page: page,
      orderBy: 'date',
      order: 'DESC',
    });
    dispatch(setUserMatchs(matchs, 'all')); // matchs : {items: [collection de matchs], total: nombre de matchs}
  };
};
export const searchUserMatchs = ({ limit, page, searchBy, orderBy }) => {
  return async (dispatch) => {
    const matchs = await api.getAllMatchsFromUser({
      limit: limit,
      page: page,
      orderBy: orderBy || 'date',
      order: 'DESC',
      searchBy: searchBy,
    });
    dispatch(setUserMatchsSearchResults(matchs)); // matchs : {items: [collection de matchs], total: nombre de matchs}
  };
};

export const getUserLastMatchs = ({ limit }) => {
  return async (dispatch) => {
    const matchs = await api.getAllMatchsFromUser({
      limit: limit,
      page: 1,
      orderBy: 'date',
      order: 'DESC',
    });
    dispatch(setUserMatchs(matchs, 'last')); // matchs : {items: [collection de matchs], total: nombre de matchs}
  };
};
export const getUserBestMatchs = ({ limit }) => {
  return async (dispatch) => {
    const matchs = await api.getAllMatchsFromUser({
      limit: limit,
      page: 1,
      orderBy: 'score',
      order: 'DESC',
    });
    dispatch(setUserMatchs(matchs, 'best')); // matchs : {items: [collection de matchs], total: nombre de matchs}
  };
};

export const getUserMatchsFavYou = ({ limit, page }) => {
  return async (dispatch) => {
    const matchs = await api.getFavsWhoFavUser({
      page,
      limit,
    });
    dispatch(setUserMatchs(matchs, 'favYou')); // matchs : {items: [collection de matchs], total: nombre de matchs}
  };
};
export const getUserMatchsFav = ({ limit, page }) => {
  return async (dispatch) => {
    const matchs = await api.getFavMatchsFromUser({
      limit,
      page,
    });
    dispatch(setUserMatchs(matchs, 'fav')); // matchs : {items: [collection de favs], total: nombre de matchs}
  };
};

export const setMatchToDisplay = (match) => ({
  type: SET_MATCH_TO_DISPLAY,
  match,
});

export const getUserMatch = (matchId) => {
  return async (dispatch) => {
    dispatch(setMatchToDisplay(null));
    dispatch(setLoading(true));
    const match = await api.getMatch(matchId);
    dispatch(setMatchToDisplay(match.data));
    // dispatch(getMatchProfileThemes());
    dispatch(setLoading(false));
  };
};

export const setMatchToDisplayTracks = (tracks) => ({
  type: SET_MATCH_TO_DISPLAY_TRACKS,
  tracks,
});

export const setMatchToDisplayArtists = (artists) => ({
  type: SET_MATCH_TO_DISPLAY_ARTISTS,
  artists,
});

export const getUserMatchTracks = ({ matchId, limit, page }) => {
  return async (dispatch) => {
    const tracks = await api.getMatchTracks({ matchId, limit, page });
    dispatch(setMatchToDisplayTracks(tracks.data));
  };
};
export const getUserMatchArtists = ({ matchId, limit, page }) => {
  return async (dispatch) => {
    const artists = await api.getMatchArtists({ matchId, limit, page });
    dispatch(setMatchToDisplayArtists(artists.data));
  };
};

export const clearMatchs = () => ({
  type: CLEAR_MATCHS,
});

// FAV
export const checkIsFav = (matchId) => {
  return async (dispatch) => {
    const { isFav, hasFavMe } = await api.checkIsFav(matchId);
    dispatch(setMatchIsFav({ isFav, hasFavMe }));
  };
};

export const setMatchIsFav = (config) => ({
  type: SET_MATCH_ISFAV,
  config,
});

// NOTIFICATIONS

export const showNotifications = (bool) => ({
  type: SHOW_NOTIFICATIONS,
  bool,
});

// CHAT

export const conversationAddMessage = (content) => ({
  type: CONVERSATION_ADD_MESSAGE,
  content,
});

export const conversationSetInputValue = (value) => ({
  type: CONVERSATION_SET_INPUT_VALUE,
  value,
});

export const setConversationsList = (conversations) => ({
  type: SET_CONVERSATIONS_LIST,
  conversations,
});

export const setConversationToDisplay = (conversation, match) => ({
  type: SET_CONVERSATION_TO_DISPLAY,
  conversation,
});

export const setConversationToDisplayMessages = (messages) => ({
  type: SET_CONVERSATION_TO_DISPLAY_MESSAGES,
  messages,
});

export const setTotalUnreadConversations = (total) => ({
  type: SET_TOTAL_UNREAD_CONVERSATIONS,
  total,
});

export const getUserConversations = ({ page, limit }) => {
  return async (dispatch) => {
    const response = await api.getUserConversations({ page, limit });
    const conversations = response.data;
    dispatch(setConversationsList(conversations));
  };
};

export const getConversationToDisplay = (id) => {
  return async (dispatch) => {
    const response = await api.getConversation(id);
    const conversation = response.data;

    dispatch(setConversationToDisplay(conversation));
  };
};

export const getConversationToDisplayMessages = ({ id, limit }) => {
  return async (dispatch) => {
    const response = await api.getConversationMessages({ id, limit });
    const conversationMessages = response.data;
    dispatch(setConversationToDisplayMessages(conversationMessages));
  };
};

export const getTotalUnreadConversations = () => {
  return async (dispatch) => {
    const response = await api.checkConversations();
    const total = response.data.total;
    dispatch(setTotalUnreadConversations(total));
  };
};

// SEARCH

export const setSearchPage = (page) => ({
  type: SET_SEARCH_PAGE,
  page,
});

// ALERT

export const showAlert = () => ({
  type: SHOW_ALERT,
});

export const hideAlert = () => ({
  type: HIDE_ALERT,
});

export const setAlertMessage = ({ message, alertType }) => ({
  type: SET_ALERT_MESSAGE,
  message,
  alertType,
});

export const alertMessage = ({ message, alertType }) => {
  return async (dispatch) => {
    dispatch(setAlertMessage({ message, alertType }));
    dispatch(showAlert());
    setTimeout(() => {
      dispatch(hideAlert());
    }, 2000);
  };
};

// ONESECOND

export const oneSecondSetCurrent = (current) => ({
  type: SET_ONESECOND_CURRENT,
  current,
});

export const oneSecondSetLasts = (lasts) => ({
  type: SET_ONESECOND_LASTS,
  lasts,
});

export const oneSecondGetData = () => {
  return async (dispatch) => {
    const currentResponse = await api.oneSecondGetCurrent();
    const lastsResponse = await api.oneSecondGetLasts();
    dispatch(oneSecondSetCurrent(currentResponse.data));
    dispatch(oneSecondSetLasts(lastsResponse.data));
  };
};

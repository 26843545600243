import "./Legal.scss";
import logo from "../../assets/logodegrade2.png";

import "./Legal.scss";

import React from "react";

const Eula = () => {
  return (
    <div className="main-container">
      <div className="Legal">
        <img src={logo} alt="Logo Banjo" className="Legal__logo" />

        <h2>Contrat de Licence de l'Utilisateur Final (EULA)</h2>
        <p className="mb20 mt20">
          VEUILLEZ LIRE ATTENTIVEMENT CE CONTRAT DE LICENCE DE L'UTILISATEUR
          FINAL ("EULA") AVANT D'UTILISER L'APPLICATION BANJO.
        </p>

        <h4>1. Acceptation du Contrat</h4>
        <p>
          En téléchargeant, installant ou utilisant l'Application Banjo
          (ci-après dénommée "l'Application"), vous acceptez d'être lié par les
          termes de cet EULA. Si vous n'acceptez pas ces termes, veuillez ne pas
          utiliser l'Application.
        </p>

        <h4>2. Licence d'utilisation</h4>
        <p>
          L'Éditeur vous accorde une licence limitée, non exclusive, non
          transférable et révocable pour utiliser l'Application sur un appareil
          iOS détenu ou contrôlé par vous, conformément aux règles d'utilisation
          énoncées dans les présentes.
        </p>

        <h4>3. Restrictions d'utilisation</h4>
        <p>Vous acceptez de ne pas :</p>
        <ul>
          <li>
            Copier, modifier ou distribuer l'Application de quelque manière que
            ce soit ;
          </li>
          <li>
            Tenter de décompiler, désassembler ou rétro-ingénierer l'Application
            ;
          </li>
          <li>
            Utiliser l'Application de manière illégale, nuisible, offensante ou
            pour enfreindre les droits d'autrui ;
          </li>
          <li>
            Tenter d'accéder à des parties de l'Application auxquelles vous
            n'avez pas été autorisé ;
          </li>
          <li>
            Utiliser l'Application d'une manière qui pourrait endommager,
            désactiver, surcharger ou compromettre les serveurs de l'Éditeur.
          </li>
        </ul>

        <h4>4. Collecte de Données</h4>
        <p>
          L'utilisation de l'Application peut impliquer la collecte et le
          traitement de données personnelles. Notre politique de confidentialité
          [Lien vers la politique de confidentialité] explique comment nous
          utilisons ces données. En utilisant l'Application, vous consentez à la
          collecte et au traitement de vos données conformément à notre
          politique de confidentialité.
        </p>

        <h4>5. Propriété Intellectuelle</h4>
        <p>
          Tous les droits de propriété intellectuelle relatifs à l'Application
          sont détenus par l'Éditeur et ses concédants de licence. Vous acceptez
          de respecter ces droits et de ne pas copier, modifier, distribuer ou
          créer des œuvres dérivées de l'Application.
        </p>

        <h4>6. Mises à Jour et Maintenance</h4>
        <p>
          L'Éditeur peut proposer des mises à jour de l'Application pour
          améliorer les fonctionnalités et la sécurité. Vous acceptez de
          maintenir votre Application à jour et de télécharger les mises à jour
          dès qu'elles sont disponibles.
        </p>

        <h4>7. Responsabilité</h4>
        <p>
          L'Application est fournie "telle quelle" et l'Éditeur ne fait aucune
          garantie quant à son fonctionnement, sa disponibilité ou son
          adéquation à un usage particulier. En aucun cas, l'Éditeur ne pourra
          être tenu responsable des dommages directs, indirects, accessoires,
          spéciaux, punitifs ou consécutifs résultant de l'utilisation de
          l'Application.
        </p>

        <h4>8. Résiliation</h4>
        <p>
          L'Éditeur se réserve le droit de résilier cette licence à tout moment
          en cas de violation de cet EULA. À la résiliation, vous devez cesser
          d'utiliser l'Application.
        </p>

        <h4>9. Droit Applicable</h4>
        <p>
          Cet EULA est régi par les lois en vigueur en France et tout litige
          découlant de cet EULA sera soumis à la juridiction exclusive des
          tribunaux de France.
        </p>

        <h4>10. Contact</h4>
        <p>
          Pour toute question concernant cet EULA ou l'Application, veuillez
          nous contacter à l'adresse suivante : contact@banjomeet.com .
        </p>
      </div>
    </div>
  );
};

export default Eula;

import './TrackRow.scss';

import React from 'react';
import trackaddGreen from '../../assets/musicdesc/trackadd-green.png';
import trackaddRed from '../../assets/musicdesc/trackadd-red.png';
import trackaddYellow from '../../assets/musicdesc/trackadd-yellow.png';
import trackaddWhite from '../../assets/musicdesc/trackadd-white.png';
import shelf from '../../assets/musicdesc/shelf.png';

import { SvgIcon } from '@material-ui/core';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const TrackFrame = ({ track, onClick, edit, isActive }) => {
  return (
    <div
      className={isActive ? 'TrackRow TrackRow--active' : 'TrackRow'}
      onClick={onClick}
    >
      <div className="TrackRow__picture">
        <img src={track.pictureUrl || ''} alt="Pochette de l'album" />
      </div>
      <div className="TrackRow__info">
        <div className="TrackRow__info--artist">{track.artist}</div>
        <div className="TrackRow__info--title">{track.name}</div>
      </div>
    </div>
  );
};

export default TrackFrame;

import './Admin.scss';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import LoadingAnimation from '../../components/LoadingAnimation';
import TrackRow from '../MusicDesc/TrackRow';

import api from '../../services/api';
import UserThumb from '../../components/Widgets/UserThumb';
import WidgetContainer from '../../components/Widgets/WidgetContainer';

import emptyuser from '../../assets/emptyuser.svg';

const User = () => {
  const { id } = useParams();
  const [tracks, setTracks] = useState({ items: [], total: 0 });
  const [matchs, setMatchs] = useState({ items: [], total: 0 });
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const responseTracks = await api.getTracksFromUserId(id);
      const responseMatchs = await api.getMatchsFromUserId(id);
      const responseUser = await api.getUserById(id);
      setTracks(responseTracks.data);
      setMatchs(responseMatchs.data);
      setUser(responseUser.data);
      setLoading(false);
    };
    init();
  }, [id]);

  return (
    <div className="main-container">
      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          {user.userData && (
            <div className="Admin__toolbar mb20">
              <div className="Admin__User--avatar">
                <img src={user.userData.pictureUrl || emptyuser} alt="" />
              </div>
              <div className="flex-v-c">
                <div className="color-2 bold">{user.userData.name}</div>
                <div className="small">
                  {user.userData.age} ans - {user.userData.city}
                </div>
              </div>
            </div>
          )}
          <div className="Admin__User">
            <div className="flex-v-c">
              <div className="fluo fluo--3 mb10">Matchs : {matchs.total}</div>
              <div className="Admin__User--matchs">
                {matchs.items.map((match) => (
                  <UserThumb key={match.id} match={match} />
                ))}
              </div>
            </div>
            <div className="ml10 flex-v-c">
              <div className="fluo fluo--3 mb10">Tracks : {tracks.total}</div>
              <div>
                {tracks.items.map((track) => (
                  <TrackRow key={track.id} track={track} />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default User;

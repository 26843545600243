import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { setInterceptors } from '../services/api';
import reducer from './reducer';

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

setInterceptors(store); //interceptors d'axios pour réagir sur les erreurs et gérer le refresh token

export default store;

import React from 'react';
import { Link } from 'react-router-dom';

import UserLabel from './UserLabel';

import emptyUser from '../../assets/emptyuser.svg';

import { hapticsImpactMedium } from '../../services/iosHaptics';

import './UserThumb.scss';
import { Fade } from 'react-reveal';
const UserThumb = ({ match, blurry }) => {
  return (
    <Fade force>
      <div
        className="UserThumb"
        style={{
          backgroundImage: match.users[0].userData.pictureUrl
            ? `url(${match.users[0].userData.pictureUrl})`
            : `url(${emptyUser})`,
        }}
        onClick={hapticsImpactMedium}
      >
        {blurry && <div className="UserThumb__blurry" />}
        <Link to={blurry ? '' : `/match/${match.id}`}>
          <div className={'UserThumb__overlay'}>
            <UserLabel match={match} />
          </div>
        </Link>
      </div>
    </Fade>
  );
};

export default UserThumb;

import React from 'react';
import { Fade } from 'react-reveal';
import loadingPicture from '../assets/loading.svg';
import loadingPictureDark from '../assets/loading_dark.svg';

const LoadingAnimation = ({ size, label, dark }) => {
  return (
    <Fade>
      <div className="flex-v-c">
        {dark ? (
          <img
            src={loadingPictureDark}
            alt=""
            style={{ maxWidth: '100px', width: `${size}px` }}
          />
        ) : (
          <img
            src={loadingPicture}
            alt=""
            style={{ maxWidth: '100px', width: `${size}px` }}
          />
        )}
        {label && <h3 className="text-normal center">{label}</h3>}
      </div>
    </Fade>
  );
};

export default LoadingAnimation;

import './Toolbar.scss';

import React from 'react';
import ToolbarButton from './ToolbarButton';

import { Bounce, Fade } from 'react-reveal';

import FavoriteIcon from '@mui/icons-material/Favorite';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { AiFillHome } from 'react-icons/ai';
import { IoChatbubbleEllipses } from 'react-icons/io5';
import { AiOutlineBackward } from 'react-icons/ai';

import { useNavigate } from 'react-router-dom';

const Toolbar = ({ backButton, userIsNotLoggedIn }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="Toolbar__margin"></div>
      <div className="Toolbar">
        {backButton && (
          <Bounce>
            <ToolbarButton
              label="Retour"
              component={<AiOutlineBackward />}
              onClick={() => navigate(-1)}
            />
          </Bounce>
        )}
        {userIsNotLoggedIn ? (
          <>
            <ToolbarButton label="Home" component={<AiFillHome />} to="/" />
          </>
        ) : (
          <>
            <ToolbarButton label="Home" component={<AiFillHome />} to="/home" />

            <ToolbarButton
              label="Favs"
              icon={FavoriteIcon}
              to="/search/favorites"
            />
            <ToolbarButton
              label="Messages"
              component={<IoChatbubbleEllipses />}
              to="/chat"
            />
            <ToolbarButton
              label="Profil"
              icon={AccountCircleIcon}
              to="/account"
            />
          </>
        )}
      </div>
    </>
  );
};

export default Toolbar;

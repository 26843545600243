import './Legal.scss';

import React from 'react';
import logo from '../../assets/logodegrade2.png';
import { SvgIcon } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const Press = () => {
  return (
    <div className="main-container">
      <div className="Legal">
        <header>
          <img src={logo} alt="Logo Banjo" className="Legal__logo" />

          <a href="/presskit-banjo.zip" className="flex-h-c large mb20">
            <SvgIcon component={DownloadForOfflineIcon} className="mr5" />
            Télécharger le kit presse
          </a>

          <h1 class="page-title center">Communiqué de presse</h1>
        </header>
        <p className="center">Février 2022</p>

        <h1 className="center mt30">Lancement de Banjo</h1>
        <div className="mb30 mt30 center">
          <hr className="mb10" />
          <h3>
            “Banjo veut casser les codes des sites de rencontre traditionnels,
            où le physique et les facteurs sociaux sont mis au premier plan”
          </h3>
          <hr />
        </div>
        <h2 className="color-1">Contexte</h2>
        <p>
          Ecouter de la musique est <strong>universel</strong>, cela concerne
          tout le monde, tous âges confondus.
        </p>
        <p>
          Aujourd’hui, les services de streaming musicaux tels que Spotify ou
          Deezer sont très largement utilisés pour écouter de la musique. Cela
          représente{' '}
          <strong>
            15 millions d’utilisateurs en France, presque 500 millions dans le
            monde
          </strong>
          . Ces chiffres sont en constante croissance.
        </p>
        <p>
          D’après une étude menée par <strong>David Greenberg</strong>, docteur
          en psychologie et chercheur à l’université de Cambridge,{' '}
          <strong>
            nos goûts musicaux sont étroitement liés à nos traits de
            personnalité
          </strong>
          , bien + qu’à des facteurs culturels.{' '}
        </p>
        <p>
          Cette étude a été réalisée auprès de 350 000 personnes dans + de 50
          pays. La conclusion de cette étude laisse entendre que la musique est
          un outil puissant pour rapprocher les gens.
        </p>
        <h2 className="color-1">De là est née l’idée de Banjo</h2>
        <p>
          Banjo analyse la musique de ses utilisateurs (par le biais de leurs
          comptes Spotify ou Deezer) pour déceler les meilleures compatibilités,
          et ainsi{' '}
          <strong>
            générer des opportunités de rencontres que l’on ne pourrait pas
            obtenir sur les sites de rencontre traditionnels
          </strong>
          .
        </p>
        <p>
          <strong>Banjo est le premier à offrir cette fonctionnalité.</strong>
        </p>
        <h2 className="color-1">Comment ça marche ?</h2>
        <p>Pour s’inscrire, il suffit d’être abonné à Spotify ou Deezer.</p>
        <p>
          L’utilisateur renseigne les informations nécessaires comme son sexe,
          son orientation sexuelle, son âge et sa ville. Pendant ce temps, sa
          musique est automatiquement analysée par Banjo.
        </p>
        <p>
          Pour que 2 utilisateurs matchent, il leur faut au moins 1 titre en
          commun dans leurs playlists. Un score de compatibilité est également
          calculé par notre algorithme.{' '}
        </p>
        <p>
          <strong>Pour son lancement, Banjo est 100% gratuit</strong>.
        </p>
        <h2 className="color-1">Pourquoi ?</h2>
        <p>
          La musique que nous écoutons en dit bien plus sur nous que nous ne le
          pensons.{' '}
        </p>
        <p>
          Banjo s’appuie sur ce lien étroit entre la musique et notre
          personnalité pour offrir à ses utilisateurs une nouvelle manière de
          matcher, <strong>bien plus pertinente</strong>.
        </p>
        <h2 className="color-1">A propos de Banjo</h2>
        <p>
          Banjo est née à Rouen, créée par Jonathan Luquet, musicien passionné
          de musique et d’informatique.
        </p>
        <p>Une application iPhone sera lancée en mars prochain.</p>
        <h2 className="color-1">Liens </h2>
        <p>
          <strong>Banjo </strong>
          <ul>
            <li>
              <a href="https://banjomeet.com/">https://banjomeet.com</a>
            </li>
            <li>
              <a href="https://www.instagram.com/banjomeet">
                https://www.instagram.com/banjomeet
              </a>
            </li>
            <li>
              <a href="https://twitter.com/banjomeet">
                https://twitter.com/banjomeet
              </a>
            </li>
          </ul>
        </p>
        <p>
          <strong>Contact </strong>
          <ul>
            <li>
              <a href="mailto:contact@banjomeet.com">contact@banjomeet.com</a>
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default Press;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';

const AppUrlListener = () => {
  let navigate = useNavigate();
  useEffect(() => {
    App.addListener('appUrlOpen', (event) => {
      console.log('AppUrlListener', event);

      const slug = event.url.split('https://banjomeet.com').pop();
      if (slug) {
        Browser.close();

        navigate(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);

  return null;
};

export default AppUrlListener;

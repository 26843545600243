export const cleanTitle = (title) => {
  let titleToReturn = title;

  if (title.indexOf('(') > 2) {
    titleToReturn = title.substr(0, title.lastIndexOf('(') - 1);
  }
  if (title.indexOf(' - ') > 2) {
    titleToReturn = title.substr(0, title.lastIndexOf(' - '));
  }
  return titleToReturn;
};

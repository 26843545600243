import './UserRow.scss';
import emptyuser from '../../assets/emptyuser.svg';
import spotifyLogo from '../../assets/spotifyWhite.png';
import deezerLogo from '../../assets/deezerWhite.png';

import React, { useState } from 'react';
import { dateDisplay } from '../../utils/dateDisplay';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import Button from '../../components/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LoadingAnimation from '../../components/LoadingAnimation';

const UserRow = ({ user }) => {
  const [syncLoading, setSyncLoading] = useState(false);
  const [clearPicLoading, setClearPicLoading] = useState(false);

  const handleSyncClick = async () => {
    setSyncLoading(true);
    await api.calcMatchsForUser(user.id);
    setSyncLoading(false);
  };

  const handleLoginClick = async () => {
    const response = await api.getTokenByUserId(user.id);
    const token = response.data.token;
    localStorage.setItem('token', token);
  };

  const handleClearPicClick = async () => {
    setClearPicLoading(true);
    await api.clearPicture(user.id);
    setClearPicLoading(false);
  };

  return (
    <div className="UserRow">
      {user.userData ? (
        <>
          <div className="UserRow__picture">
            <Link to={`/admin/user/${user.id}`}>
              <img src={user.userData.pictureUrl || emptyuser} alt="" />
            </Link>
          </div>
          <div className="UserRow__column UserRow__column--large">
            <div className="UserRow__info color-2">{user.id}</div>
            <div className="UserRow__info small">{user.email}</div>
            <div className="UserRow__info small">
              <span className="color-2">Créé le : </span>{' '}
              {dateDisplay(user.createdDate)}
            </div>
            <div className="UserRow__info small">
              <span className="color-2">Modifié le : </span>
              {dateDisplay(user.updatedDate)}
            </div>
          </div>
          <div className="UserRow__column UserRow__column--normal">
            <div className="UserRow__info color-2 ">
              {user.userData.name} - {user.userData.age} ans -{' '}
              {user.userData.city}
            </div>
            <div className="UserRow__info">
              {user.userData.gender} {' > '} {user.userData.orientation}
            </div>
            <div className="UserRow__info">
              {user.userData.searchDistance}km {' > '}{' '}
              {user.userData.searchAgeDifference} ans
            </div>
            <div className="UserRow__info" onClick={handleLoginClick}>
              Prendre la main
            </div>
          </div>
          <div className="UserRow__column UserRow__column--large ">
            <div className="UserRow__info">{user.userData.description}</div>
          </div>
          <div className="UserRow__column UserRow__column--small">
            <div className="UserRow__info ">
              <img
                className="UserRow__strLogo"
                src={
                  user.streamingPlatform === 'spotify'
                    ? spotifyLogo
                    : deezerLogo
                }
                alt=""
              />
            </div>
          </div>
          <div className="UserRow__column UserRow__column--small">
            <div className="flex-v-c">
              {syncLoading ? (
                <LoadingAnimation />
              ) : (
                <Button
                  label="Calc matchs"
                  color={2}
                  picture={RefreshIcon}
                  onClick={handleSyncClick}
                />
              )}
              {clearPicLoading ? (
                <LoadingAnimation />
              ) : (
                <Button
                  label="Clear pic"
                  color={1}
                  picture={HighlightOffIcon}
                  onClick={handleClearPicClick}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="UserRow__picture">
            <img src={emptyuser} alt="" />
          </div>
          <div className="UserRow__column">
            <div className="UserRow__info">{user.id}</div>
            <div className="UserRow__info">{user.email}</div>
            <div className="UserRow__info">{dateDisplay(user.createdDate)}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserRow;

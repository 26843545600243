import './Alert.scss';

import React from 'react';
import { connect } from 'react-redux';

import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import { SvgIcon } from '@material-ui/core';

const mapStateToProps = (state) => ({
  show: state.alert.show,
  message: state.alert.message,
  alertType: state.alert.alertType,
});

const Alert = ({ show, message, alertType }) => {
  return (
    <div className={show ? 'Alert' : 'Alert Alert--hide'}>
      <div className="Alert__content ">
        {' '}
        {alertType === 'success' ? (
          <SvgIcon component={CheckCircleRoundedIcon} className="mr5 color-2" />
        ) : (
          <SvgIcon component={NotificationsIcon} className="mr5 color-3" />
        )}
        {message}
      </div>
    </div>
  );
};
export default connect(mapStateToProps)(Alert);

import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import LoadingAnimation from '../../components/LoadingAnimation';
import UserRow from './UserRow';
import PaginationToolbar from '../../components/PaginationToolbar';

import TagFacesRoundedIcon from '@mui/icons-material/TagFacesRounded';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';

const Admin = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({ items: [], total: 0 });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(60);
  const [inputValue, setInputValue] = useState('');

  const handleChange = async (e, value) => {
    setPage(value);
  };

  const handleUpdateAllClick = async () => {
    setLoading(true);
    await api.calcMatchsForAllUsers();
    setLoading(false);
  };

  const handleInputChange = async (e) => {
    setInputValue(e.target.value);
    const result = await api.searchUsers({
      page,
      limit: limit,
      term: e.target.value,
    });
    setData(result);
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const response = await api.getAllUsers({ page, limit: limit });
      console.log(response);
      setData(response);
      setLoading(false);
      console.log('DATA', data);
    };
    init();
  }, [page]);
  return (
    <div className="Admin main-container">
      <div className="Admin__toolbar">
        <div className="mr20">
          <span className="color-3">{data.total}</span> utilisateurs
        </div>
        <input type="text" onChange={handleInputChange} />
        <Button
          label="Recalculer tous les matchs"
          onClick={handleUpdateAllClick}
          picture={TagFacesRoundedIcon}
          color={3}
        />
        <Button
          label="Stats"
          onClick={() => navigate('/admin/stats')}
          picture={TagFacesRoundedIcon}
          color={2}
        />
      </div>

      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          {data.items.map((user) => (
            <UserRow key={user.id} user={user} />
          ))}
        </>
      )}
      <PaginationToolbar
        count={Math.ceil(data.total / limit)}
        page={page}
        onChange={handleChange}
      />
    </div>
  );
};

export default Admin;

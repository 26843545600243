import './ToolbarButton.scss';
import { NavLink } from 'react-router-dom';

import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { hapticsImpactMedium } from '../../services/iosHaptics';

const ToolbarButton = ({ label, icon, component, to, onClick }) => {
  const handleClick = () => {
    hapticsImpactMedium();
    if (onClick) {
      onClick();
    }
  };
  return (
    <NavLink
      to={to || ''}
      className={({ isActive }) =>
        isActive && !onClick ? 'ToolbarButton--active' : ''
      }
    >
      <div className="ToolbarButton" onClick={handleClick}>
        {icon && <SvgIcon component={icon} />}
        {component && component}
        <div className="ToolbarButton__label">{label}</div>
      </div>
    </NavLink>
  );
};

export default ToolbarButton;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import api from '../../services/api';
import Button from '../../components/Button';
import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded';
import ReactCodeInput from 'react-verification-code-input';
import ErrorList from '../../components/ErrorList';
import { Fade } from 'react-reveal';

import {
  setErrors,
  setUserSpecificValue,
  setSigninStepNext,
} from '../../store/actions';
import LoadingAnimation from '../../components/LoadingAnimation';

const mapStateToProps = (state) => ({
  email: state.user.email,
  errors: state.messages.errors,
  music_sync: state.music_sync,
});
const mapDispatchToProps = (dispatch) => ({
  setSigninStepNext: () => dispatch(setSigninStepNext()),

  setErrors: (errors) => dispatch(setErrors(errors)),
  setUserSpecificValue: (target, value) =>
    dispatch(setUserSpecificValue(target, value)),
});

const SinginMailConfirm = ({
  email,
  music_sync,
  setErrors,
  errors,
  setSigninStepNext,
}) => {
  useEffect(() => {
    const init = async () => {
      await api.getActivationCode();
    };
    init();
  }, []);
  const navigate = useNavigate();
  const [emailIsSend, setEmailIsSend] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (value) => {
    const errors = [];
    try {
      setErrors([]);
      setLoading(true);
      const response = await api.checkEmailVerificationCode(value);
      console.log('checkEmailVerificationCode : ', response);
      setLoading(false);
      navigate('/description');
    } catch (error) {
      if (error.response.status !== 200) {
        errors.push('Code invalide');
        setLoading(false);

        setErrors(errors);
      }
      const errorMessage = error.response.data.detail; //message d'erreur renvoyé par api
      console.log(errorMessage);
    }
    console.log('onComplete!', value);
  };

  const handleResend = async () => {
    try {
      await api.resendEmailVerificationCode();
      setErrors([]);
      errors.push('Nouveau code envoyé');
      setErrors(errors);
    } catch (error) {
      setErrors([]);
      errors.push('Erreur, veuillez réessayer plus tard');
      setErrors(errors);
    }
    setEmailIsSend(true);
  };

  return (
    <div className="mb20 flex-v-c">
      {music_sync.isSynced ? (
        <Fade right>
          <q>C'est la dernière étape !</q>
          <h4 className="mb10 mt10 center">
            Confirme le code envoyé à <em>{email}</em>
          </h4>
          {loading ? (
            <LoadingAnimation label="Patiente quelques instants" />
          ) : (
            <ReactCodeInput
              type="number"
              fields={6}
              onComplete={(value) => handleSubmit(value)}
            />
          )}
          <div className="mt20 flex-v-c ">
            <ErrorList errors={errors} />
            {!emailIsSend && (
              <>
                <p className="mb10">Tu n'as pas reçu de code ?</p>
                <Button
                  color={2}
                  picture={AutorenewRoundedIcon}
                  label="Renvoyer un code"
                  onClick={handleResend}
                />
              </>
            )}
          </div>
        </Fade>
      ) : (
        <Fade>
          <LoadingAnimation label="Patiente jusqu'à la fin de la synchronisation" />
        </Fade>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SinginMailConfirm);

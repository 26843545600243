import './Notification.scss';

import React from 'react';
import { connect } from 'react-redux';

import { showNotifications } from '../../store/actions';

import { SvgIcon } from '@material-ui/core';
import { useNavigate } from 'react-router';

import Button from '../../components/Button';

import NotificationsIcon from '@mui/icons-material/Notifications';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

const mapDispatchToProps = (dispatch) => ({
  showNotifications: (bool) => dispatch(showNotifications(bool)),
});

const Notification = ({ notification, showNotifications, row }) => {
  const navigate = useNavigate();
  const goTo = (route) => {
    showNotifications(false);
    navigate(route);
  };
  const style =
    notification.color === 1
      ? 'color-1'
      : notification.color === 2
      ? 'color-2'
      : notification.color === 3
      ? 'color-3'
      : '';

  const icon =
    notification.type === 'alert'
      ? NotificationsIcon
      : notification.type === 'chat'
      ? ForumRoundedIcon
      : null;

  return (
    <div className={row ? 'NotificationRow' : 'Notification'}>
      <div className={row ? 'NotificationRow__label' : 'Notification__label'}>
        <SvgIcon component={icon} className={style} /> {notification.label}
      </div>
      <Button
        label={notification.buttonLabel}
        color="dark"
        picture={ChevronRightRoundedIcon}
        reverse
        onClick={() => goTo(notification.route)}
      />
    </div>
  );
};

export default connect(null, mapDispatchToProps)(Notification);

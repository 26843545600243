import './ErrorList.scss';

import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { SvgIcon } from '@material-ui/core';

const ErrorList = ({ errors }) => {
  return (
    <div className="flex-v-c">
      {errors &&
        errors.map((error, i) => (
          <div key={i} className="Error">
            <SvgIcon component={ErrorIcon} />
            <div className="Error__content">{error}</div>
          </div>
        ))}
    </div>
  );
};

export default ErrorList;

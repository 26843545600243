const front_url = 'https://banjomeet.com';
const api_url = 'https://matchistadorapi.cleverapps.io';

const front_url_dev = 'http://127.0.0.1:3000';
const api_url_dev = 'http://127.0.0.1:4000';

export const streaming_auth_service_url =
  'https://matchistadorauthservice.cleverapps.io';

export const aws_s3_url = 'https://matchistapics.s3.eu-west-3.amazonaws.com';

const dev = false;

export const mobile = false;

export const frontUrl = dev ? front_url_dev : front_url;
export const apiUrl = dev ? api_url_dev : api_url;
export const spotifyRedirectUri = mobile //obligé de faire un custom scheme pour spotify car deeplink ne fonctionne pas (je ne comprends pas pourquoi)
  ? 'banjomeet://spotifyconnect'
  : 'https://banjomeet.com/spotifyconnect';

import React from 'react';
import './UserLabel.scss';
import logoM from '../../assets/logomin.png';

const UserLabel = ({ match, noDetails }) => {
  return (
    <div className="UserLabel">
      <div className="UserLabel__name">{match.users[0].userData.name}</div>
      <div className="UserLabel__location">
        {match.users[0].userData.age} ans •{' '}
        {match.users[0].userData.city || 'pas de ville'}
      </div>
      {!noDetails && (
        <>
          <div className="UserLabel__score">
            <div>{match.score}</div>

            <img src={logoM} alt="Banjo points" />
          </div>
        </>
      )}
    </div>
  );
};

export default UserLabel;

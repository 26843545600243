import './Legal.scss';

import React from 'react';
import { useNavigate } from 'react-router';
import logo from '../../assets/logodegrade2.png';

import Button from '../../components/Button';

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const TermsOfUse = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  return (
    <div className="main-container">
      <div className="Legal">
        <img src={logo} alt="Logo Matchistador" className="Legal__logo" />
        <h2>Conditions générales d'utilisation</h2>
        <h3>Article 1</h3>
        <h4>Objet</h4>
        <p>
          Les présentes CGU ou Conditions Générales d’Utilisation encadrent
          juridiquement l’utilisation des services du site Banjo (ci-après
          dénommé « le site »). Constituant le contrat entre la société
          Matchistador SAS, l’Utilisateur, l’accès au site doit être précédé de
          l’acceptation de ces CGU. L’accès à cette plateforme signifie
          l’acceptation des présentes CGU.
        </p>
        <h3>Article 2</h3>
        <h4>Mentions légales</h4>
        <p>
          L’édition du site Banjo est assurée par la société Matchistador SAS,
          SAS au capital de 1.000€ immatriculée sous le numéro 909 871 584 au
          RCS de ROUEN. L’hébergeur du site matchistador.com est la société OVH
          SAS dont le siège social est situé au 2 rue Kellermann - 59100 Roubaix
          - France.
        </p>
        <h3>Article 3</h3>
        <h4>Accès au site</h4>
        <p>
          L’accès au site est réservé aux personnes majeures. Le site Banjo
          permet d’accéder gratuitement à un ensemble de services ayant pour
          objet de favoriser les rencontres entre personnes à des fins
          personnelles, de loisirs et non commerciales. Certains services
          additionnels peuvent être payants pour l'Utilisateur.{' '}
        </p>
        <p>
          Le site est accessible gratuitement depuis n’importe où par tout
          utilisateur disposant d’un accès à Internet. Tous les frais
          nécessaires pour l’accès aux services (matériel informatique,
          connexion Internet…) sont à la charge de l’utilisateur. L’accès aux
          services dédiés aux membres s’effectue à l’aide d’un identifiant et
          d’un mot de passe. Pour des raisons de maintenance ou autres, l’accès
          au site peut être interrompu ou suspendu par l’éditeur sans préavis ni
          justification.
        </p>
        <h3>Article 4</h3>
        <h4>Collecte des données</h4>
        <p>
          Pour la création du compte de l’Utilisateur, la collecte des
          informations au moment de l’inscription sur le site est nécessaire et
          obligatoire. Conformément à la loi n°78-17 du 6 janvier relative à
          l’informatique, aux fichiers et aux libertés, la collecte et le
          traitement d’informations personnelles s’effectuent dans le respect de
          la vie privée. Suivant la loi Informatique et Libertés en date du 6
          janvier 1978, articles 39 et 40, l’Utilisateur dispose du droit
          d’accéder, de rectifier, de supprimer et d’opposer ses données
          personnelles. L’exercice de ce droit s’effectue par email à l'adresse
          suivante :
          <a href="mailto:rgpd@matchistador.com"> rgpd@matchistador.com</a>
        </p>
        <h3>Article 5</h3>
        <h4>Propriété intellectuelle</h4>
        <p>
          Les marques, logos ainsi que les contenus du site Banjo sont protégés
          par le Code de la propriété intellectuelle et par le droit d’auteur.
          La reproduction et la copie des contenus par l’Utilisateur requièrent
          une autorisation préalable du site. Dans ce cas, toute utilisation à
          des usages commerciaux ou à des fins publicitaires est proscrite.
        </p>
        <h3>Article 6</h3>
        <h4>Responsabilité</h4>
        <p>
          Bien que les informations publiées sur le site soient réputées
          fiables, le site se réserve la faculté d’une non-garantie de la
          fiabilité des sources. Les informations diffusées sur le site Banjo
          sont présentées à titre purement informatif et sont sans valeur
          contractuelle. En dépit des mises à jour régulières, la responsabilité
          du site ne peut être engagée en cas de modification des dispositions
          administratives et juridiques apparaissant après la publication. Il en
          est de même pour l’utilisation et l’interprétation des informations
          communiquées sur la plateforme. Le site décline toute responsabilité
          concernant les éventuels virus pouvant infecter le matériel
          informatique de l’Utilisateur après l’utilisation ou l’accès à ce
          site. Le site ne peut être tenu pour responsable en cas de force
          majeure ou du fait imprévisible et insurmontable d’un tiers. La
          garantie totale de la sécurité et la confidentialité des données n’est
          pas assurée par le site. Cependant, le site s’engage à mettre en œuvre
          toutes les méthodes requises pour le faire au mieux.
        </p>
        <h3>Article 7</h3>
        <h4>Liens hypertexte</h4>
        <p>
          Le site peut être constitué de liens hypertextes. En cliquant sur ces
          derniers, l’Utilisateur sortira de la plateforme. Cette dernière n’a
          pas de contrôle et ne peut pas être tenue responsable du contenu des
          pages web relatives à ces liens.
        </p>
        <h3>Article 8</h3>
        <h4>Cookies</h4>
        <p>
          Lors des visites sur le site, l’installation automatique d’un cookie
          sur le logiciel de navigation de l’Utilisateur peut survenir. Les
          cookies correspondent à de petits fichiers déposés temporairement sur
          le disque dur de l’ordinateur de l’Utilisateur. Ces cookies sont
          nécessaires pour assurer l’accessibilité et la navigation sur le site.
          Ces fichiers ne comportent pas d’informations personnelles et ne
          peuvent pas être utilisés pour l’identification d’une personne.
          L’information présente dans les cookies est utilisée pour améliorer
          les performances de navigation sur le site Banjo. En naviguant sur le
          site, l’Utilisateur accepte les cookies. Leur désactivation peut
          s’effectuer via les paramètres du logiciel de navigation.
        </p>
        <h3>Article 9</h3>
        <h4>Publications par l'Utilisateur</h4>
        <div>
          En utilisant le site, l'Utilisateur ne doit jamais :
          <ul>
            <li>
              publier ses coordonnées personnelles ou diffuser les informations
              personnelles d’une autre personne (adresse e-mail, adresse
              postale, numéro de téléphone, etc.) de quelque manière que ce soit
              (dans une description de profil, sur une photo, etc.)
            </li>
            <li>
              publier tout contenu qui viole ou enfreint les droits d’autrui, y
              compris ceux relatifs à la publicité, vie privée, droits d’auteur,
              marques déposées ou tout autre droit de propriété intellectuelle
              ou contractuel.
            </li>
            <li>
              usurper l’identité de toute personne ou entité, notamment par le
              biais de ses Photos de Profil.
            </li>
            <li>
              solliciter des mots de passe à quelque fin que ce soit ou des
              informations d’identification personnelle à des fins commerciales
              ou illégales auprès des autres Utilisateurs.
            </li>
            <li>spammer, demander de l’argent ou escroquer un Utilisateur.</li>
            <li>
              publier tout contenu à caractère haineux, menaçant, sexuellement
              explicite ou pornographique ; qui incite à la violence ou contient
              de la nudité ou de la violence explicite ou gratuite.
            </li>
            <li>
              publier tout contenu qui promeuve le racisme, le sectarisme, la
              haine ou les dommages physiques de quelque nature que ce soit
              contre un groupe ou un individu.
            </li>
            <li>
              malmener, « traquer », intimider, agresser, harceler, maltraiter
              ou diffamer toute personne.
            </li>
            <li>utiliser le site afin de nuire à Banjo.</li>
            <li>
              utiliser les Services à des fins illégales ou interdites par les
              présentes Conditions d’utilisation.
            </li>
            <li>
              utiliser les Services pour faire la promotion ou encourager des
              relations de nature transactionnelle et/ou des services sexuels
              commerciaux et/ou des actes sexuels non consensuels.
            </li>
            <li>
              utiliser tout(e) logiciels, scripts, robots ou tout autre moyen ou
              processus (notamment des robots d’indexation, des modules
              d’extension de navigateur et compléments, ou toute autre
              technologie) visant à accéder, extraire, indexer, fouiller les
              données ou reproduire ou éluder de quelque façon que ce soit la
              structure de navigation ou la présentation du Service ou de ses
              contenus.
            </li>
            <li>
              utiliser le compte d’un autre utilisateur, partager un compte avec
              un autre utilisateur ou gérer plusieurs comptes.
            </li>
            <li>
              créer un autre compte si Banjo a déjà explicitement résilié son
              compte.
            </li>
          </ul>
        </div>
        <p>
          Une violation par l'Utilisateur de l’une de ces règles de conduite
          constitue une violation grave de ses obligations contractuelles en
          vertu des présentes Conditions d’utilisation.
        </p>
        <p>
          Banjo se réserve le droit de résilier le compte d’un Utilisateur sans
          aucun remboursement des services payants en cas de violation des
          présentes obligations.
        </p>
      </div>
      <div className="flex-v-c mt20 mb30">
        <Button
          onClick={handleClick}
          label="Retour"
          color={2}
          picture={ArrowCircleLeftIcon}
        />
      </div>
    </div>
  );
};

export default TermsOfUse;

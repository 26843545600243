import "./Landing.scss";

import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import logo from "../assets/banjo3.png";
import ecoute from "../assets/ecoute.jpg";

import backalbums from "../assets/backalbums.jpg";
import fondmatchs from "../assets/fondmatchs.jpg";
import ilsenparlent from "../assets/ilsenparlent.png";
import signindesc from "../assets/signindesc.jpg";
import appCapture from "../assets/store/1261iphone11.jpg";

import api from "../services/api";

const Landing = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const init = async () => {
      await api.pushVisit();
    };
    init();
    const isAuth = localStorage.getItem("isAuth");
    const token = localStorage.getItem("token");
    if (isAuth && token) {
      navigate("/home");
    }
  }, []);
  return (
    <div className="Landing">
      <div className="Landing__header">
        {/* <video
          id="background-video"
          preload
          autoPlay
          muted
          loop
          playsInline
          poster=""
          className="Landing__header--overlay"
        >
          <source src={video} type="video/mp4" />
        </video> */}
        <img src={backalbums} alt="" className="Landing__header--overlay" />
        <img src={logo} alt="Logo Banjo" className="logo" />
        <h2 className="slogan">
          Des rencontres sur mesure, grâce à votre musique
        </h2>
        {/* <StoreButtons /> */}
      </div>
      <div className="flex-v-c">
        <div className="hero hero--announcement">
          <h2 className="mb20">
            <em className="text-gradient">Banjo</em> ferme ses portes.{" "}
          </h2>
          <h2>
            <em className="text-gradient">
              Merci aux milliers d'utilisateurs pour cette belle aventure
              musicale !
            </em>
          </h2>
        </div>
      </div>
      <h2 className="hero">
        La musique est la langue des <em className="text-gradient">émotions</em>
        , trouvez <em className="text-gradient">l'accord parfait</em>
      </h2>

      <h3
        className="subhero center"
        style={{
          marginTop: "100px",
        }}
      >
        <hr />
        <q className="p20">
          <em className="text-gradient">banjo</em> veut faire de la musique un
          point de départ pour de véritables connexions
        </q>
        <hr />
      </h3>
      <section
        className="flex-v-c"
        style={{
          marginTop: "100px",
        }}
      >
        <h2>Ils en parlent</h2>
        <img
          src={ilsenparlent}
          alt="la presse en parle"
          style={{ maxWidth: "600px", width: "100%" }}
        />
      </section>
      <div className="flex-v-c">
        <img
          style={{ maxWidth: "400px", width: "100%", margin: "100px auto" }}
          src={appCapture}
          alt=""
        />
      </div>
      <section className="secondary-container">
        <div className="alt-section-container">
          <div className="alt-section-container__content">
            <h2 className="color-2">Synchronise ta musique</h2>
            <h2>
              Associe ton compte Spotify ou Deezer et laisse{" "}
              <em className="text-gradient">banjo</em> s’occuper du reste,
              simple et rapide.
            </h2>
          </div>
        </div>
        <div className="alt-section-container alt-section-container--reverse">
          <div className="alt-section-container__content">
            <h2 className="color-1 mb10">Ta musique, ta personnalité</h2>
            <h2>
              Terminé les longs paragraphes de présentation et les critères
              physiques, décris-toi plutôt à l’aide de tes titres préférés, ceux
              qui reflètent qui tu es, qui tu as été, et qui tu es devenu(e).
            </h2>
          </div>
        </div>
      </section>
      <h3 className="subhero center">
        <q className="p20">Une expérience unique</q>{" "}
        <q className="p20">Un nouveau moyen de matcher</q>{" "}
        <q className="p20">Banjo casse les codes des applis de rencontres</q>
      </h3>
      <section className="secondary-container">
        <div className="section-container">
          <div className="section-container__title fluo fluo--1">
            T'écoutes quoi ?
          </div>
          <img
            src={fondmatchs}
            alt=""
            className="section-container__img bubble2"
          />
          <div className="section-container__content ">
            <h2 className="color-2 mb20">Tu as des goûts bizarres ?</h2>
            <h2>
              Tu n'es pas seul(e) !{" "}
              <em className="text-gradient bold">banjo</em> propose des Matchs
              basés sur la compatibilité musicale. C'est fou ce que l'on peut
              apprendre sur une personne à travers sa musique !
            </h2>
          </div>
        </div>
        <div className="section-container">
          <div className="section-container__title fluo fluo--2">
            Ça match !
          </div>
          <img src={ecoute} alt="" className="section-container__img bubble" />
          <div className="section-container__content ">
            <h2 className="color-2 mb20">
              Quoi de mieux que la musique pour engager la conversation ?
            </h2>
            <h2>
              Trouve ta meilleure inspiration, et commence la discussion, le
              sujet est tout trouvé !
            </h2>
          </div>
        </div>
      </section>
      {/* <StoreButtons /> */}
      {/* <div className="flex-v-c ">
        <h4 className="mb20 center fluo fluo--1">
          <a href="https://www.linkedin.com/in/jonathan-luquet-03271812a/">
            Contact
          </a>
        </h4>
      </div> */}
    </div>
  );
};

export default Landing;
